import Api from './api';

const AlunosService = {
    index: () => Api.get('/alunos'),
    create: (params) => Api.post('/alunos', (params)),
    editar: (id, params) => Api.put(`/alunos/${id}`, (params)),
    search_cpf: (params) => Api.get(`/alunos/${params}`),
    // add_treinamento: (id, otherId) => Api.put(`/alunos/${id}/adicionar-treinamento/${otherId}`)
}

export default AlunosService;