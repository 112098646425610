import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
// import Moment from 'react-moment';
// import 'moment-timezone';
import "../../../styles/locais.scss";
import "../../../styles/reset.scss";
import Modal from "../../layouts/modal";
import ModalDangerSmall from '../../layouts/modalDangerSmall';
import LocaisService from '../../../services/locais';

export default function ContentAdministracaoLocais() {
    const navigate = useNavigate();
    const [locais, setLocais] = useState([]);
    const [localAtivo, setLocalAtivo] = useState([]);
    const [modalNovoLocal, setModalNovoLocal] = useState(false);
    const [modalLocal, setModalLocal] = useState(false);
    const [modalEditarLocal, setModalEditarLocal] = useState(false);
    const [modalDeleteLocal, setModalDeleteLocal] = useState(false);
    const [finds, setFinds] = useState("");

    async function getLocais() {
        const res = await LocaisService.index();
        setLocais(res.data);
    };

    useEffect(() => {
        getLocais();
    }, []);

    const createLocal = async event => {
        event.preventDefault();

        const formData = new FormData(event.target);
        const data = Object.fromEntries(formData);
        
        try {
            await LocaisService.create(data);
            getLocais();
            setModalNovoLocal(false);
        } catch (error) {
            console.log(error)
        }
    };

    const editLocal = async event => {
        event.preventDefault();

        const formData = new FormData(event.target);
        const data = Object.fromEntries(formData);
        
        try {
            await LocaisService.edit(localAtivo._id, data);
            getLocais();
            setModalEditarLocal(false);
        } catch (error) {
            console.log(error)
        }
    };

    async function desativarLocal(id) {
        try {
            await LocaisService.desabilitar(id);
            getLocais();
            setModalDeleteLocal(false);
        } catch (error) {
            console.log(error);
        }
    };

    // FILTRO
    function busca(a) {
        if (finds === "") {
            return a
        } else {
            return a.nome.toLowerCase().includes(finds.toLowerCase()) || a.endereco.toLowerCase().includes(finds.toLowerCase()) || a.cidade.toLowerCase().includes(finds.toLowerCase())
        }
    }

    document.title = `Locais - Centro de Treinamento`;

    return(
        <Fragment>
            <main id='main-locais'>
                <div className="conteudo-locais">
                    <div className="cabecalho-conteudo">
                        <h2>Locais de Treinamento</h2>
                        <div className="buttons-conteudo">
                            <button className="button-red" onClick={()=> navigate(`/administracao`)}>Voltar</button>
                            <button className="button-green" onClick={() => setModalNovoLocal(true)}>Novo local</button>
                        </div>
                    </div>
                    <div className='container-search'>
                        <input type='text' placeholder='Buscar' onChange={e => {setFinds(e.target.value)}}/>
                        <FontAwesomeIcon icon={faSearch}/>
                    </div>
                    <div className="tabela-dados">
                        <div className="cabecalho-dados">
                            <div className="column-35">Nome</div>
                            <div className="column-45">Logradouro</div>
                            <div className="column-20">Cidade / Estado</div>
                        </div>
                        <div className="corpo-dados corpo-seguradoras">
                            { locais.length === 0 ? 
                                <div className="row-dados">
                                    <div className="column-zerado">
                                        Não foi encontrado nenhum resultado.
                                    </div>
                                </div>
                                : (
                                    (locais.filter(({excluido}) => excluido === false).filter(busca).slice(0).reverse().map((item, index) => (
                                        <Fragment>
                                            <div key={index} className="row-dados" onClick={() => {setModalLocal(true); setLocalAtivo(item)}}>
                                                <div className="column-35">
                                                    {item.nome}
                                                </div>
                                                <div className="column-45">
                                                    {item.endereco}
                                                </div>
                                                <div className="column-20">
                                                    {item.cidade} / {item.estado}
                                                </div>
                                            </div>
                                        </Fragment>
                                    )))
                                )
                            }
                            <h5>Exibindo {locais.filter(({excluido}) => excluido === false).filter(busca).length} {locais.filter(({excluido}) => excluido === false).filter(busca).length === 1 ? "resultado" : "resultados"}</h5>
                        </div>
                    </div>
                    {/* <div className="lista-dados">
                        <div className="cabecalho-dados">
                            <div className="column-razao-social">Razão Social</div>
                            <div className="column-cnpj">CNPJ</div>
                            <div className="column-telefone">Telefone</div>
                            <div className="column-email">E-mail</div>
                        </div>
                        <div className="corpo-dados">
                            {seguradoras?.slice(0).reverse().map((seguradoras) => (
                                <div key={seguradoras._id} className="row-empresas">
                                    <div className="column-razao-social">
                                        {seguradoras.razao}
                                    </div>
                                    <div className="column-cnpj">
                                        {seguradoras.cnpj}
                                    </div>
                                    <div className="column-telefone">
                                        {seguradoras.telefone}
                                    </div>
                                    <div className="column-email">
                                        {seguradoras.email}
                                    </div>
                                </div>
                            ))}
                            <h5>Exibindo {seguradoras.filter(({ excluido }) => excluido === false).length} {seguradoras.filter(({ excluido }) => excluido === false).length === 1 ? "resultado" : "resultados"}</h5>
                        </div>
                    </div> */}
                </div>
            </main>
            {modalNovoLocal ? (
                <Modal titulo="Novo Local" onClose={() => {setModalNovoLocal(false)}}>
                    <form onSubmit={createLocal}>
                        <div id="campos-nova-seguradora">
                            <div className="campo-form campo-50">
                                <label htmlFor="nome">Nome</label>
                                <input type="text" name="nome" id="nome" autocomplete="off"/>
                            </div>
                            <div className="campo-form campo-25">
                                <label htmlFor="cidade">Cidade</label>
                                <input type="text" name="cidade" id="cidade" required/>
                            </div>
                            <div className="campo-form campo-25">
                                <label htmlFor="estado">Estado</label>
                                <select name="estado" id="estado">
                                    <option value="default">Selecione</option>
                                    <option value="AC">Acre</option>
                                    <option value="AL">Alagoas</option>
                                    <option value="AM">Amazonas</option>
                                    <option value="AP">Amapá</option>
                                    <option value="BA">Bahia</option>
                                    <option value="CE">Ceará</option>
                                    <option value="DF">Distrito Federal</option>
                                    <option value="ES">Espirito Santo</option>
                                    <option value="GO">Goiás</option>
                                    <option value="MA">Maranhão</option>
                                    <option value="MT">Mato Grosso</option>
                                    <option value="MS">Mato Grosso do Sul</option>
                                    <option value="MG">Minas Gerais</option>
                                    <option value="PA">Pará</option>
                                    <option value="PB">Paraíba</option>
                                    <option value="PR">Paraná</option>
                                    <option value="PE">Pernambuco</option>
                                    <option value="PI">Piauí</option>
                                    <option value="RJ">Rio de Janeiro</option>
                                    <option value="RN">Rio Grande do Norte</option>
                                    <option value="RS">Rio Grande do Sul</option>
                                    <option value="SC">Santa Catarina</option>
                                    <option value="SP">São Paulo</option>
                                    <option value="SE">Sergipe</option>
                                    <option value="RO">Rondônia</option>
                                    <option value="RR">Roraima</option>
                                    <option value="TO">Tocantins</option>
                                </select>
                            </div>
                            <div className="campo-form campo-100">
                                <label htmlFor="endereco">Endereço</label>
                                <input type="text" name="endereco" id="endereco"/>
                            </div>
                        </div>
                        <div className='buttons-form'>
                            <button type="reset" className="button-red" onClick={() => {setModalNovoLocal(false)}}>Cancelar</button>
                            <button type="submit" className="button-green">Cadastrar</button>
                        </div>
                    </form>
                </Modal>
            ): null}
            {modalLocal ? (
                <Modal titulo="Informações Local" onClose={() => {setModalLocal(false)}}>
                    <div class="info-modal">
                        <div className="campo-info campo-65">
                            <h5>Nome</h5>
                            <h4>{localAtivo.nome}</h4>
                        </div>
                        <div className="campo-info campo-35">
                            <h5>Cidade / Estado</h5>
                            <h4>{localAtivo.cidade} / {localAtivo.estado}</h4>
                        </div>
                        <div className="campo-info campo-100">
                            <h5>Endereço</h5>
                            <h4>{localAtivo.endereco}</h4>
                        </div>
                    </div>
                    <div className='buttons-info'>
                        <button type="reset" className="button-red" onClick={() => {setModalLocal(false)}}>Cancelar</button>
                        <button type="button" className="button-darkred" onClick={() => {setModalLocal(false); setModalDeleteLocal(true)}}>Excluir</button>
                        <button type="button" className="button-cyan" onClick={() => {setModalLocal(false); setModalEditarLocal(true)}}>Editar</button>
                    </div>
                </Modal>
            ): null}
            {modalEditarLocal ? (
                <Modal titulo="Editar Local" onClose={() => {setModalEditarLocal(false)}}>
                    <form onSubmit={editLocal}>
                        <div id="campos-nova-seguradora">
                            <div className="campo-form campo-50">
                                <label htmlFor="nome">Nome</label>
                                <input type="text" name="nome" id="nome" autocomplete="off" defaultValue={localAtivo.nome}/>
                            </div>
                            <div className="campo-form campo-25">
                                <label htmlFor="cidade">Cidade</label>
                                <input type="text" name="cidade" id="cidade" required defaultValue={localAtivo.cidade}/>
                            </div>
                            <div className="campo-form campo-25">
                                <label htmlFor="estado">Estado</label>
                                <select name="estado" id="estado" defaultValue={localAtivo.estado}>
                                    <option value="default">Selecione</option>
                                    <option value="AC">Acre</option>
                                    <option value="AL">Alagoas</option>
                                    <option value="AM">Amazonas</option>
                                    <option value="AP">Amapá</option>
                                    <option value="BA">Bahia</option>
                                    <option value="CE">Ceará</option>
                                    <option value="DF">Distrito Federal</option>
                                    <option value="ES">Espirito Santo</option>
                                    <option value="GO">Goiás</option>
                                    <option value="MA">Maranhão</option>
                                    <option value="MT">Mato Grosso</option>
                                    <option value="MS">Mato Grosso do Sul</option>
                                    <option value="MG">Minas Gerais</option>
                                    <option value="PA">Pará</option>
                                    <option value="PB">Paraíba</option>
                                    <option value="PR">Paraná</option>
                                    <option value="PE">Pernambuco</option>
                                    <option value="PI">Piauí</option>
                                    <option value="RJ">Rio de Janeiro</option>
                                    <option value="RN">Rio Grande do Norte</option>
                                    <option value="RS">Rio Grande do Sul</option>
                                    <option value="SC">Santa Catarina</option>
                                    <option value="SP">São Paulo</option>
                                    <option value="SE">Sergipe</option>
                                    <option value="RO">Rondônia</option>
                                    <option value="RR">Roraima</option>
                                    <option value="TO">Tocantins</option>
                                </select>
                            </div>
                            <div className="campo-form campo-100">
                                <label htmlFor="endereco">Endereço</label>
                                <input type="text" name="endereco" id="endereco" defaultValue={localAtivo.endereco}/>
                            </div>
                        </div>
                        <div className='buttons-form'>
                            <button type="reset" className="button-red" onClick={() => {setModalEditarLocal(false); setModalLocal(true)}}>Cancelar</button>
                            <button type="submit" className="button-green">Salvar</button>
                        </div>
                    </form>
                </Modal>
            ): null}
            {modalDeleteLocal ? (
                <ModalDangerSmall mensagem={['Deseja excluir o local', <br/>,<span>{localAtivo.nome}</span>,"?"]} onClose={() => {setModalDeleteLocal(false)}}>
                    <Fragment>
                        <div className='buttons-form'>
                            <button type="button" className="button-red" onClick={() => {setModalDeleteLocal(false); setModalLocal(true)}}>Cancelar</button>
                            <button type="submit" className="button-blue" onClick={() => {desativarLocal(localAtivo._id)}}>Excluir</button>
                        </div>
                    </Fragment>
                </ModalDangerSmall>
            ): null}
        </Fragment>
    )
};