import Api from './api';

const TreinamentosService = {
    index: () => Api.get('/treinamentos'),
    limit_treinamento: (limit) => Api.get(`/treinamentos?resultados=${limit}`),
    paginado_treinamento: (pagina) => Api.get(`/treinamentos?pagina=${pagina}`),
    treinamento: (id) => Api.get(`/treinamentos/${id}`),
    create: async (params) => await Api.post(`/treinamentos`, (params)),
    duplicar: async (params) => await Api.post(`/treinamentos/duplicar`, (params)),
    editar_treinamento: async (id, params) => await Api.put(`/treinamentos/${id}`, (params)),
    atualizar_treinamento: async (id, params) => await Api.put(`/treinamentos/${id}/atualizar`, (params)),
    alterar_status: async (id, params) => await Api.put(`/treinamentos/${id}/status`, (params)),
    adicionar_aluno: async (id, params) => await Api.put(`/treinamentos/${id}/aluno`, (params)),
    // ts: async (id, params) => await Api.put(`/treinamentos/ts/${id}`, (params)),
    concluir_aluno: async (id, otherId, params) => await Api.put(`/treinamentos/${id}/aluno/${otherId}/concluir`, (params)),
    excluir_aluno: async (id, otherId) => await Api.delete(`/treinamentos/${id}/aluno/${otherId}`),
    excluir_treinamento: async (id) => await Api.put(`/treinamentos/${id}/desabilitar`),
    restaurar_treinamento: async (id) => await Api.put(`/treinamentos/${id}/habilitar`),
    delete_treinamento: async (id) => await Api.delete(`/treinamentos/${id}`)
}

export default TreinamentosService;