import React, { Fragment, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import QRCode from 'react-qr-code';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRotateRight, faArrowRotateLeft, faPrint } from '@fortawesome/free-solid-svg-icons'
import Moment from 'react-moment';
import 'moment-timezone';
import moment from 'moment/moment';
import "../../styles/certificados.scss";
import "../../styles/reset.scss";
import LogoCTCPP from '../../../src/assets/logos/LogoCTCPP.png'
import AssinaturaJorge from '../../../src/assets/assinaturas/presidente.png'
// import UsersService from '../../services/users';
import CertificadosService from '../../services/certificados';
import Loading from '../layouts/loading';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import ImagensService from '../../services/imagens';
// import gerarCertificado from './lista';
// import base64 from 'base64-img';

export default function ContentAdministracaoEmpresas() {
    const [certificado, setCertificado] = useState([]);
    const [logo, setLogo] = useState([]);
    const [assinaturaPresidente, setAssinaturaPresidente] = useState([]);
    const [ladoAtivo, setLadoAtivo] = useState("frente");
    const { id } = useParams();

    async function getCertificado() {
        const res = await CertificadosService.certificado(id);
        setCertificado(res.data);
    }

    async function getImagens() {
        const res = await ImagensService.index();
        setLogo(res.data.filter(({ nome }) => nome === "Logo")[0].url);
        setAssinaturaPresidente(res.data.filter(({ nome }) => nome === "Assinatura Presidente")[0].url);
    }

    useEffect(() =>{
        getCertificado();
        getImagens();
        // eslint-disable-next-line
    },[]);
    
    function criarPDF() {
        pdfMake.vfs = pdfFonts.pdfMake.vfs;

        const dadosConteudo = certificado.conteudo_programatico.map((item) => {
            return[
                `${item}`,
            ]
        })

        const details = [
            {
                columns: [
                    {
                        columns: [
                            {
                                image: logo,
                                width: 140
                            }
                        ],
                        width: '20%'
                    },
                    {
                        width: '60%',
                        height: 100,
                        fontSize: 26,
                        alignment: 'center',
                        margin: [0, 50, 0, 0],
                        text: 'CERTIFICADO',
                        bold: true
                    },
                    {
                        
                        columns: [
                            {
                                margin: [0, 15, 0, 0],
                                fit: 100,
                                qr: `https://192.168.0.19:3000/certificado/${certificado._id}`
                            }
                        ],
                        width: '20%',
                        alignment: 'right'
                    },
                ],
                margin: [0, 10, 0, 20]
            },
            {
                text: `${certificado.curso.descritivo.toUpperCase()} - ${certificado.curso.titulo}`,
                alignment: 'center',
                fontSize: 20,
                margin: [0, 0, 0, 40],
                bold: true
            },
            {
                margin: [30, 0, 30, 0],
                lineHeight: 1.3,
                fontSize: 15,
                alignment: 'justify',
                text: `Que se confere a ${certificado.aluno}, portador do CPF ${certificado.cpf}, concluiu com aprovação o curso de ${certificado.curso.descritivo} em conformidade com a ${certificado.curso.titulo}, realizado nos dias 06, 07, 08, 09 e 10 de Fevereiro, com carga horária de ${certificado.curso.carga_horaria}h, promovido pelo Centro de Treinamento e Capacitação Profissional do Paraná e realizado na cidade de ${certificado.treinamento.local.cidade} / ${certificado.treinamento.local.estado}.`
            },
            {
                margin: [0, 45, 0, 80],
                fontSize: 16,
                bold: true,
                alignment: 'center',
                text: `${certificado.treinamento.local.cidade}, ${moment(certificado.concluido_at).format('DD [de] MMMM [de] YYYY')}.`
            },
            {
                width: 150,
                image: assinaturaPresidente,
                absolutePosition: {x:100, y:430},
            },
            {
                canvas: [
                    { type: 'line', x1: 5, y1: 5, x2: 250, y2: 5, lineWidth: 1 }, //Up line
                ],
                absolutePosition: {x:50, y:500}
            },
            {
                text: `Jorge Pedro da Cruz Santos`,
                absolutePosition: {x:100, y:510}
            },
            {
                text: `Presidente`,
                absolutePosition: {x:150, y:525}
            },
            {
                text: `CTCPP`,
                absolutePosition: {x:160, y:540}
            },
            {
                canvas: [
                    { type: 'line', x1: 5, y1: 5, x2: 250, y2: 5, lineWidth: 1 }, //Up line
                ],
                absolutePosition: {x:530, y:500}
            },
            {
                text: `${certificado.aluno}`,
                alignment: 'center',
                width: 250,
                absolutePosition: {x:530, y:510}
            },
            {
                text: `CPF: ${certificado.cpf}`,
                alignment: 'center',
                width: 250,
                absolutePosition: {x:530, y:525}
            },
            {
                table: {
                    headerRows: 1,
                    widths: ['*'],
                    body: [
                        [{text: `Conteúdo Programático`, alignment: 'center', fontSize: 14, bold: true, margin: [0, 5, 0, 5], border: [true, true, true, false]}],
                        [
                            {
                                ul: [
                                    ...dadosConteudo
                                ],
                                lineHeight: 1.2,
                                fontSize: 11,
                                border: [true, false, true, true],
                                margin: [15, 10, 15, 15]
                            }
                        ]
                    ]
                },
                margin: [0, 40, 0, 0],
                pageBreak: 'before'
            },
            {
                table: {
                    widths: ['*'],
                    body: [
                        [{text: `Este certificado tem a validade de 24 meses (2 anos) após a realização deste treinamento.`, alignment: 'center', fontSize: 12, bold: true, margin: [0, 5, 0, 5], border: [true, false, true, true]}],
                    ]
                }
            },
            {
                table: {
                    widths: ['50%', '50%'],
                    body: [
                        [
                            {
                                table: {
                                    widths: ['100%'],
                                    body: [
                                        [
                                            
                                            {
                                                width: 130,
                                                image: `${certificado.treinamento.responsavel_tecnico.assinatura}`,
                                                border: [false, false, false, false],
                                                // alignment: 'center',
                                                absolutePosition: {x: 170, y:450},
                                            },
                                        ],
                                        [
                                            {
                                                canvas: [
                                                    { type: 'line', x1: 5, y1: 5, x2: 250, y2: 5, lineWidth: 1 }, //Up line
                                                ],
                                                // image: `${certificado.treinamento.responsavel_tecnico.assinatura}`,
                                                // width: 150,
                                                absolutePosition: {x: 105, y:500},
                                                // alignment: 'center',
                                                margin: [0, 0, 0, 0],
                                                border: [false, false, false, false]
                                            }
                                        ],
                                        [
                                            {
                                                text: `${certificado.treinamento.responsavel_tecnico.name}`,
                                                alignment: 'center',
                                                margin: [0, 80, 0, 0],
                                                bold: true,
                                                border: [false, false, false, false]
                                            },
                                        ],
                                        [
                                            {
                                                text: "Responsável Técnico - Engenheiro de Segurança 166427D",
                                                alignment: 'center',
                                                border: [false, false, false, false],
                                                margin: [0, 0, 0, 10],
                                            },
                                        ],
                                    ],
                                },
                            },
                            {
                                table: {
                                    widths: ['100%'],
                                    body: [
                                        [
                                            
                                            {
                                                width: 130,
                                                image: `${certificado.treinamento.instrutor.assinatura}`,
                                                border: [false, false, false, false],
                                                // alignment: 'center',
                                                absolutePosition: {x: 535, y:455},
                                            },
                                        ],
                                        [
                                            {
                                                canvas: [
                                                    { type: 'line', x1: 5, y1: 5, x2: 250, y2: 5, lineWidth: 1 }, //Up line
                                                ],
                                                absolutePosition: {x: 485, y:500},
                                                // alignment: 'center',
                                                margin: [0, 0, 0, 0],
                                                border: [false, false, false, false]
                                            }
                                        ],
                                        [
                                            {
                                                text: `${certificado.treinamento.instrutor.name}`,
                                                alignment: 'center',
                                                lineHeight: .9,
                                                margin: [0, 80, 0, 0],
                                                bold: true,
                                                border: [false, false, false, false]
                                            },
                                        ],
                                        [
                                            {
                                                text: "Instrutor - TST 13805",
                                                alignment: 'center',
                                                border: [false, false, false, false],
                                                margin: [0, 0, 0, 10],
                                            },
                                        ],
                                    ],
                                },
                            }
                        ],
                    ],
                },
                margin: [0, 10, 0, 0]
            }
        ];

        const pdfDefinitions = {
            pageSize: 'A4',
            pageOrientation: 'landscape',
            pageMargins: [44, 0, 44, 0],
            
            content: [details],
            info: {
                title: `${certificado.aluno}.pdf`
            },
        }

        pdfMake.createPdf(pdfDefinitions).open();
    }

    document.title = `Certificado ${certificado.aluno} - Centro de Treinamento`;

    return(
        <Fragment>
            { certificado.length !== 0 && certificado.treinamento.titulo !== "" && certificado.curso.descritivo !== "" ? 
            <main id='main-certificados'>
                <div id='certificado'>
                    <button className='button-blue' onClick={() => {criarPDF()}}><FontAwesomeIcon icon={faPrint}></FontAwesomeIcon></button>
                    {/* <button className='button-blue' onClick={() => {setLadoAtivo(ladoAtivo === "frente" ? "verso" : "frente")}}>{ladoAtivo === "frente" ? <Fragment><FontAwesomeIcon icon={faArrowRotateRight}/> </Fragment> :  <Fragment><FontAwesomeIcon icon={faArrowRotateLeft}/> </Fragment>}</button> */}
                    <div className='frente-certificado' style={{ display: ladoAtivo === "frente" ? "block" : "none" }}>
                        <div className='topo-frente-certificado'>
                            <div className='campo-topo-left'>
                                <img src={logo} alt="Logo Centro de Treinamento e Capacitação Profissional do Paraná"></img>
                            </div>
                            <h1>Certificado</h1>
                            <div className='campo-topo-right'>
                                <QRCode
                                size={256}
                                style={{ maxHeight: "70%", height: "60%" }}
                                value={`https://192.168.0.19:3000/certificado/${certificado._id}`}
                                viewBox={`0 0 256 256`}
                                />
                            </div>
                        </div>
                        <h2>{certificado.curso.descritivo} - {certificado.curso.titulo}</h2>
                        <p className='descricao-certificado'>
                            Que se confere a {certificado.aluno}, portador do CPF {certificado.cpf}, concluiu com aprovação o curso de {certificado.curso.descritivo} em conformidade com a {certificado.curso.titulo}, realizado no dia { certificado.treinamento.datas.length === 1 ? (<Moment format='DD [de] MMMM [de] YYYY'>{certificado.treinamento.datas[0].dia}</Moment>) : ("nos dias", certificado.treinamento.datas.map((item, index) => {<Moment format='DD' key={index}>{item.dia}</Moment>}))}, com carga horária de {certificado.curso.carga_horaria}h, promovido pelo Centro de Treinamento e Capacitação Profissional do Paraná e realizado na cidade de {certificado.treinamento.local.cidade} / {certificado.treinamento.local.estado}.
                        </p>
                        <h3 className='local-treinamento'>{certificado.treinamento.local.cidade}, <Moment format='DD [de] MMMM [de] YYYY'>{certificado.concluido_at}</Moment>.</h3>
                        <div className="assinaturas-certificado">
                            <div className="assinatura">
                                <img src={assinaturaPresidente}></img>
                                <p>Jorge Pedro da Cruz Santos</p>
                                <p>Presidente</p>
                                <p>CTCPP</p>
                            </div>
                            <div className="assinatura">
                                <p className="nome-colaborador">{certificado.aluno}</p>
                                <p className="cpf-colaborador">CPF: {certificado.cpf}</p>
                            </div>
                        </div>
                    </div>
                    <div className='verso-certificado' style={{ display: ladoAtivo === "verso" ? "flex" : "none" }}>
                        <h2>Conteúdo Programático</h2>
                        <ul>
                        {certificado.conteudo_programatico.map((item, index) => (
                            <li key={index}>{item}</li>
                        ))}
                        </ul>
                    </div>
                </div>
            </main>
            :
            <Loading>
                
            </Loading>
            }
        </Fragment>
    )
};