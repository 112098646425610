import { Fragment } from 'react';
import Header from '../../components/header/index';
import ContentAdministracaoLocais from '../../components/administracao/locais';

const LocaisScreen = (props) => (
  <Fragment>
    <Header ativo={props.ativo}/>
    <ContentAdministracaoLocais/>
  </Fragment>
)

export default LocaisScreen;