import { Fragment } from 'react';
import Header from '../../components/header/index';
import ContentInstrutores from '../../components/administracao/instrutores';

const InstrutoresScreen = (props) => (
  <Fragment>
    <Header ativo={props.ativo}/>
    <ContentInstrutores/>
  </Fragment>
)

export default InstrutoresScreen;