// import Moment from 'react-moment';
import 'moment-timezone';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import moment from 'moment/moment';

export default function GerarListaDePresenca(treinamento, logo, data) {
    function ordemAlfabetica(a, b){
        return a.nome < b.nome ? -1 : a.nome > b.nome ? 1 : 0;
    }

    pdfMake.vfs = pdfFonts.pdfMake.vfs;

    const dadosConteudo = treinamento.dados_curso.conteudo_programatico.map((item) => {
        return [
            `${item}`,
        ]
    })

    const dadosAlunos = treinamento.alunos.sort(ordemAlfabetica).map((item) => {
        return [
            {text: `${item.aluno.nome}`},{text: `${item.aluno.cpf}`},{text: ``}
        ]
    });

    const details = [
        {
            columns: [
                {
                    columns: [
                        {
                            image: logo,
                            width: 140
                        }
                    ],
                    margin: [20, 0, 0, 0],
                    width: '20%'
                },
                {
                    width: '80%',
                    height: 100,
                    fontSize: 20,
                    alignment: 'center',
                    margin: [50, 50, 0, 0],
                    text: 'Centro de Treinamento e Capacitação Profissional do Paraná',
                    bold: true
                },
            ],
            margin: [0, 0, 0, 20]
        },
        {
            text: `LISTA DE PRESENÇA`,
            alignment: 'center',
            fontSize: 20,
            margin: [0, 0, 0, 40],
            bold: true
        },
        {
            table: {
                widths: ['auto', '*'],
                body: [
                    [{text: 'Assunto:', bold: true, border: [true, true, false, true]}, {text: `${treinamento.dados_curso.descritivo} - ${treinamento.dados_curso.titulo}`, margin: [0, 0, 20, 0], border: [false, true, true, true]}],
                ]
            }
        },
        ...(treinamento.empresa) ?
        [
            {
                table: {
                    widths: ['auto', '*'],
                    body: [
                        [{text: 'Empresa:', bold: true, border: [true, false, false, true]}, {text: `${treinamento.empresa.razao_social}`, margin: [0, 0, 20, 0], border: [false, false, true, true]}],
                    ]
                }
            },
        ] : [],
        {
            table: {
                widths: ['auto', '*', 'auto', '*'],
                body: [
                    [{text: `Data:`, bold: true, border: [true, false, false, true]}, {text: `${moment(data.dia).format("DD/MM/YYYY")}`, margin: [0, 0, 20, 0], border: [false, false, true, true]},
                     {text: `Duração:`, bold: true, border: [true, false, false, true]}, {text: `${data.duracao}h`, margin: [0, 0, 20, 0], border: [false, false, true, true]}]
                ]
            }
        },
        {
            table: {
                widths: ['*'],
                headerRows: 1,
                body: [
                    [{text: 'Conteúdo Programático:', fontSize: 14, bold: true, alignment: 'center', margin: [0, 5, 0, 0], border: [true, true, true, false]}],
                    [
                        {
                            ul: [
                                ...dadosConteudo
                            ],
                            lineHeight: 1.4,
                            border: [true, false, true, true],
                            margin: [15, 10, 15, 15]
                        }
                    ]
                ]
            },
            pageBreak: 'after',
            margin: [0, 20, 0, 0],
        },
        {
            table: {
                headerRows: 1,
                widths: ['50%', '18%', '32%'],
                body: [
                    [{text: `Nome`, fontSize: 13, bold: true, margin: [0, 5, 0, 5]}, {text: `CPF`, fontSize: 13, bold: true, margin: [0, 5, 0, 5]}, {text: `Assinatura`, bold: true, fontSize: 13, margin: [0, 5, 0, 5]}],
                    ...dadosAlunos
                ]
            },
            margin: [0, 20, 0, 0],
        },
        {
            canvas: [
                { type: 'line', x1: 5, y1: 5, x2: 250, y2: 5, lineWidth: 1 }, //Up line
            ],
            alignment: 'center',
            margin: [0, 150, 0, 0]
        },
        {
            text: `${treinamento.dados_instrutor.nome}`,
            alignment: 'center',
            margin: [0, 5, 0, 0],
        },
        {
            text: "Instrutor",
            alignment: 'center',
        }
    ];

    const pdfDefinitions = {
        pageSize: 'A4',
        pageOrientation: 'portrait',
        pageMargins: [25, 25, 25, 25],

        info: {
            title: `Lista de Presença`,
        },
        content: [details]
    }

    pdfMake.createPdf(pdfDefinitions).open();
}