import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Moment from 'react-moment';
import 'moment-timezone';
import "../../../styles/log.scss";
import "../../../styles/reset.scss";
import Api from '../../../services/api';
import Loading from '../../layouts/loading';

export default function ContentAdministracaoLog() {
    const navigate = useNavigate();
    const [logs, setLogs] = useState([]);

    useEffect(() => {
        Api.get('/logs').then(({ data }) => {
            setLogs(data);
        });
    }, []);
    
    async function deleteAll() {
        Api.delete('/logs').then(() => {
            window.location.reload()
        });
    };

    document.title = `Logs - Centro de Treinamento`;

    return(
        <Fragment>
            { logs !== "" ? 
            <main>
                <div className="conteudo-log">
                    <div className="cabecalho-conteudo">
                        <h2>Logs</h2>
                        <div className="buttons-conteudo">
                            <button className="button-red" onClick={()=> navigate(`/administracao`)}>Voltar</button>
                            <button className="button-blue" onClick={() => {window.print()}}>Imprimir</button>
                            <button className="button-darkred" onClick={() => {deleteAll()}}>Deletar Registros</button>
                        </div>
                    </div>
                    <div className="tabela-dados">
                        <div className="cabecalho-dados">
                            <div className="column-15">Data</div>
                            <div className="column-15">Usuário</div>
                            <div className="column-60">Registro</div>
                        </div>
                        <div className="corpo-dados corpo-corretores">
                            { logs.length === 0 ? 
                                <div className="row-dados">
                                    <div className="column-zerado">
                                        Não foi encontrado nenhum resultado.
                                    </div>
                                </div>
                            : 
                                logs.slice().reverse().map((item, index) => (
                                    <div key={index} className="row-dados">
                                        <div className="column-15">
                                            <Moment format='DD/MM/YYYY [-] HH:mm'>{item.data}</Moment>
                                        </div>
                                        <div className="column-15">
                                            { item.usuario ?
                                                <span style={item.usuario.permission === 1 ? {color: "#007bff"} : (item.usuario.permission === 2 ? {color: "#000080"} : (item.usuario.permission === 3 ? {color: "#FF0000"} : {color: "#006400"}))}>{item.usuario.name}</span>
                                            :
                                                <span>{item.nao_usuario}</span>
                                            }
                                        </div>
                                        <div className="column-60">
                                            {item.registro}
                                        </div>
                                    </div>
                                ))
                            }
                            <h5>Exibindo {logs.length} {logs.length === 1 ? "resultado" : "resultados"}</h5>
                        </div>
                    </div>
                </div>
            </main>
            :
            <Loading/>
            }
        </Fragment>
    )
};