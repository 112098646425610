import { Fragment } from 'react';
import Header from '../../components/header/index';
import ContentCursos from '../../components/cursos';

const CursosScreen = (props) => (
  <Fragment>
    <Header ativo={props.ativo}/>
    <ContentCursos/>
  </Fragment>
)

export default CursosScreen;