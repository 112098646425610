import React, { Fragment } from "react";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import PrivateRoute from './components/auth/private_route';
import LoginScreen from './screens/login/loginScreen';
import HomeScreen from './screens/home/homeScreen';
import TreinamentosScreen from './screens/treinamentos/treinamentosScreen';
import TreinamentoScreen from './screens/treinamentos/treinamentoScreen';
import CursosScreen from './screens/cursos/cursosScreen';
import CertificadosScreen from './screens/certificados/certificadosScreen';
import AdministracaoScreen from './screens/administracao/administracaoScreen';
import UsuariosScreen from './screens/administracao/usuariosScreen';
import InstrutoresScreen from './screens/administracao/instrutoresScreen';
import LocaisScreen from './screens/administracao/locaisScreen';
import ExcluidosScreen from './screens/administracao/excluidosScreen';
import ChamadosScreen from './screens/chamados/chamadosScreen';
import ChamadoScreen from "./screens/chamados/chamadoScreen";
import ChamadosBugScreen from "./screens/chamados/chamadosBugsScreen";
import ChamadosCorrecoesScreen from "./screens/chamados/chamadosCorrecoesScreen";
import ChamadosSugestoesScreen from "./screens/chamados/chamadosSugestoesScreen";
import ChamadosSuporteScreen from "./screens/chamados/chamadosSuporteScreen";
import LogScreen from "./screens/administracao/logScreen";
import RegistrosScreen from './screens/administracao/registrosScreen';
import AlunosScreen from './screens/alunos/alunosScreen';
// import PrivatePages from "./components/auth/private_route/private_pages";
import EmpresasScreen from "./screens/administracao/empresasScreen";
import ConfiguracoesScreen from "./screens/configuracoes/configuracoesScreen";
import AtalhosScreen from "./screens/administracao/atalhosScreen";
// import FinanceiroScreen from "./screens/financeiro/financeiroScreen";
// import ParceirosScreen from "./screens/financeiro/parceirosScreen";
// import PlanoContasScreen from "./screens/financeiro/planoContasScreen";
// import ContasScreen from "./screens/financeiro/contasScreen";
// import FluxoCaixaScreen from "./screens/financeiro/fluxoCaixaScreen";
// import CaixasScreen from "./screens/financeiro/caixasScreen";

var newLang = 'pt';
document.documentElement.lang = newLang;

const App = () => (
  <Fragment>
    <BrowserRouter>
        <Routes>
            <Route exact path='/login' element={<LoginScreen/>} />
            <Route exact path='/certificado/:id' element={<CertificadosScreen ativo="certificados"/>}/>
            <Route exact path='/' element={<PrivateRoute/>}>
                <Route exact path='/' element={<HomeScreen ativo="inicio"/>}/>
                <Route exact path='/alunos' element={<AlunosScreen ativo="alunos"/>}/>
                <Route exact path='/treinamentos' element={<TreinamentosScreen ativo="treinamentos"/>}/>
                <Route exact path='/treinamento/:id' element={<TreinamentoScreen ativo="treinamentos"/>}/>
                {/* <Route Route exact path='/cursos' element={<PrivatePages permission={1}/>}> */}
                  <Route exact path='/cursos' element={<CursosScreen ativo="cursos"/>}/>
                {/* </Route> */}
                {/* <Route exact path='/corretora/sinistros' element={<SinistrosScreen ativo="corretora"/>}/>
                <Route exact path='/corretora/sinistros/:id' element={<SinistroScreen ativo="corretora"/>}/>
                <Route exact path='/corretora/historicos' element={<HistoricosScreen ativo="corretora"/>}/> */}
                <Route exact path='/administracao' element={<AdministracaoScreen ativo="administração"/>}/>
                <Route exact path='/administracao/usuarios' element={<UsuariosScreen ativo="administração"/>}/>
                {/* <Route exact path='/administracao/testes' element={<TestesScreen ativo="administração"/>}/> */}
                <Route exact path='/administracao/instrutores' element={<InstrutoresScreen ativo="administração"/>}/>
                <Route exact path='/administracao/locais' element={<LocaisScreen ativo="administração"/>}/>
                <Route exact path='/administracao/log' element={<LogScreen ativo="administração"/>}/>
                <Route exact path='/administracao/registros' element={<RegistrosScreen ativo="administração"/>}/>
                <Route exact path='/administracao/excluidos' element={<ExcluidosScreen ativo="administração"/>}/>
                <Route exact path='/administracao/empresas' element={<EmpresasScreen ativo="administração"/>}/>
                <Route exact path='/administracao/atalhos' element={<AtalhosScreen ativo="administração"/>}/>
                {/* <Route exact path='/administracao/emails' element={<EmailsScreen ativo="administração"/>}/>
                <Route exact path='/administracao/notificacoes' element={<NotificacoesScreen ativo="administração"/>}/>*/}
                {/* <Route exact path='/financeiro' element={<FinanceiroScreen ativo="financeiro"/>}/>
                <Route exact path='/financeiro/parceiros' element={<ParceirosScreen ativo="financeiro"/>}/>
                <Route exact path='/financeiro/plano-de-contas' element={<PlanoContasScreen ativo="financeiro"/>}/>
                <Route exact path='/financeiro/contas' element={<ContasScreen ativo="financeiro"/>}/>
                <Route exact path='/financeiro/fluxo-de-caixa' element={<FluxoCaixaScreen ativo="financeiro"/>}/>
                <Route exact path='/financeiro/caixas' element={<CaixasScreen ativo="financeiro"/>}/> */}
                <Route exact path='/chamados' element={<ChamadosScreen ativo="chamados"/>}/>
                <Route exact path='/chamados/bugs' element={<ChamadosBugScreen ativo="chamados"/>}/>
                <Route exact path='/chamados/bugs/:id' element={<ChamadoScreen ativo="chamados"/>}/>
                <Route exact path='/chamados/sugestoes' element={<ChamadosSugestoesScreen ativo="chamados"/>}/>
                <Route exact path='/chamados/sugestoes/:id' element={<ChamadoScreen ativo="chamados"/>}/>
                <Route exact path='/chamados/correcoes' element={<ChamadosCorrecoesScreen ativo="chamados"/>}/>
                <Route exact path='/chamados/correcoes/:id' element={<ChamadoScreen ativo="chamados"/>}/>
                <Route exact path='/chamados/suporte' element={<ChamadosSuporteScreen ativo="chamados"/>}/>
                <Route exact path='/chamados/suporte/:id' element={<ChamadoScreen ativo="chamados"/>}/>
                <Route exact path='/configuracoes' element={<ConfiguracoesScreen ativo="configurações"/>}/>
            </Route>
        </Routes>
    </BrowserRouter>
  </Fragment>
)

export default App;