import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTrashCan } from '@fortawesome/free-solid-svg-icons'
import Moment from 'react-moment';
import 'moment-timezone';
import 'moment/locale/pt-br'
import "../../../styles/suporte.scss";
import "../../../styles/reset.scss";
import Modal from "../../layouts/modal";
import Loading from '../../layouts/loading';
import ChamadosService from '../../../services/chamados';
import UsersService from '../../../services/users';
Moment.globalLocale = 'pt-BR'

export default function ContentSuporteChamados() {
    const navigate = useNavigate();
    const [suportes, setSuportes] = useState();
    const [userLogado, setUserLogado] = useState();
    const [modalCriarChamado, setModalCriarChamado] = useState(false);

    async function getChamados() {
        const response = await ChamadosService.suporte();
        setSuportes(response.data.filter(({ excluido }) => excluido === false));
    }

    async function getLogado() {
        const response = await UsersService.logado();
        setUserLogado(response.data.user);
    }

    useEffect(() =>{
        getLogado();
        getChamados();
    },[]);

    async function createChamado(evt) {
        evt.preventDefault();

        const formData = new FormData(evt.target)
        let form = Object.fromEntries(formData);
        form.area = "Suporte";

        try {
            await ChamadosService.create(form);
            await getChamados();
            setModalCriarChamado(false);
        } catch (error) {
            console.log(error);
        }
    }

    document.title = `Chamados de Suporte - Centro de Treinamento`;

    return(
        <Fragment>
            { suportes?.lenght !== 0  && userLogado?.lenght !== 0 ?
            <main className='main'>
                <div className="conteudo-suporte">
                    <div className="cabecalho-conteudo">
                        <h2>Chamados de Suporte</h2>
                        <div className="buttons-conteudo">
                            <button className="button-red" onClick={() => {navigate('/chamados')}}>Voltar</button>
                            <button className="button-blue" onClick={() => {window.print()}}>Imprimir</button>
                            <button className="button-green" onClick={() => setModalCriarChamado(true)}>Novo Chamado</button>
                        </div>
                    </div>
                    <div className='lista-chamados'>
                        <div className='item-principal-chamados' onClick={() => {navigate('/chamados/suporte/finalizados')}}>
                            <FontAwesomeIcon icon={faCheck}/>
                            <div className='titulo-categoria'>
                                <h2>Finalizados</h2>
                                <div className='descricao-categoria'>
                                    <p>Todos os chamados finalizados na categoria de suporte</p>
                                </div>
                            </div>
                        </div>
                        { userLogado?.permission === 1 ? <div className='item-principal-chamados' onClick={() => {navigate('/chamados/suporte/lixeira')}}>
                            <FontAwesomeIcon icon={faTrashCan}/>
                            <div className='titulo-categoria'>
                                <h2>Lixeira</h2>
                                <div className='descricao-categoria'>
                                    <p>Todos os chamados que foram excluidos da categoria de suporte</p>
                                </div>
                            </div>
                        </div>
                        :
                        null
                        }
                        <div className='itens-chamados'>
                            { suportes?.length !== 0 ?
                                ( suportes?.filter(({excluido}) => excluido === false).filter(({concluido}) => concluido === false).slice().reverse().map((item, index) => (
                                    <div className='item-chamados' key={index} onClick={() => {navigate(`/chamados/correcoes/${item._id}`)}}>
                                        <div className='titulo-categoria'>
                                            <h2>{item.titulo}</h2>
                                            <div className='descricao-categoria'>
                                                <p>Por {(() => {
                                                    switch (true) {
                                                        case (item.created_by.permission === 1):
                                                            return(<span style={{color: "#007bff"}}>{item.created_by.name}</span>)
                                                        case (item.created_by.permission === 2):
                                                            return(<Fragment><span style={{color: "#000080"}}>{item.created_by.name}</span></Fragment>)
                                                        case (item.created_by.permission === 3):
                                                            return(<Fragment><span style={{color: "#FF0000"}}>{item.created_by.name}</span></Fragment>)
                                                        case (item.created_by.permission === 4):
                                                            return(<Fragment><span style={{color: "#006400"}}>{item.created_by.name}</span></Fragment>)
                                                        default:
                                                        break;
                                                    }
                                                })()}, <Moment format='DD [de] MMMM'>{item.created_at}</Moment></p>
                                            </div>
                                        </div>
                                    </div>
                                )))
                            : 
                                <div className='column-zerado'>Não foi encontrado nenhum chamado de suporte.</div> 
                            }
                        </div>
                    </div>
                </div>
            </main>
            :
            (
            <Loading/>
            )}
            {modalCriarChamado ? (
                <Modal titulo="Novo Chamado" width="50%" onClose={() => {setModalCriarChamado(false)}}>
                    <form onSubmit={createChamado}>
                        <div id="campos-novo-chamado">
                            <div className="campo-form campo-100">
                                <label htmlFor="titulo">Título</label>
                                <input type="titulo" name="titulo" id="titulo"/>
                            </div>
                            <div className="campo-form campo-100">
                                <label htmlFor="descricao">Descrição:</label>
                                <textarea type="text" name="descricao" id="descricao" rows="5"/>
                            </div>
                        </div>
                        <div className='buttons-form'>
                            <button type="reset" className="button-red" onClick={() => {setModalCriarChamado(false)}}>Cancelar</button>
                            <button type="submit" className="button-green">Cadastrar</button>
                        </div>
                    </form>
                </Modal>
            ): null}
        </Fragment>
    )
};