import { Fragment } from 'react';
import Header from '../../components/header/index';
import ContentAdministracaoRegistros from '../../components/administracao/registros';

const RegistrosScreen = (props) => (
  <Fragment>
    <Header ativo={props.ativo}/>
    <ContentAdministracaoRegistros/>
  </Fragment>
)

export default RegistrosScreen;