import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashCan, faUserPlus } from '@fortawesome/free-solid-svg-icons'
// import Moment from 'react-moment';
// import 'moment-timezone';
import "../../../styles/outros.scss";
import "../../../styles/reset.scss";
import Api from '../../../services/api';
import Modal from "../../layouts/modal";
import UsersService from '../../../services/users';

export default function ContentAdministracaoInstrutores() {
    const navigate = useNavigate();
    const [users, setUsers] = useState([]);
    const [modalIsOpen, setModalIsOpen] = useState(false)

    useEffect(() => {
        Api.get('/usuarios').then(({ data }) => {
            setUsers(data.user);
        });
    }, []);

    async function adicionarCorretor(id) {
        await UsersService.adicionar_instrutor(id);
        window.location.reload();
    }

    async function removeCorretor(id) {
        await UsersService.excluir_instrutor(id);
        window.location.reload();
    }

    const usuariosInstrutor = users?.filter(({ instrutor }) => instrutor === true);
    
    document.title = `Instrutores - Centro de Treinamento`;

    return(
        <Fragment>
            <main id='main-outros'>
                <div className="conteudo-sinistros">
                    <div className="cabecalho-conteudo">
                        <h2>Instrutores</h2>
                        <div className="buttons-conteudo">
                            <button className="button-red" onClick={()=> navigate(`/administracao`)}>Voltar</button>
                            <button className="button-blue" onClick={() => {window.print()}}>Imprimir</button>
                            <button className="button-green add-corretor" onClick={() => setModalIsOpen(true)}>Adicionar</button>
                        </div>
                    </div>
                    <div className="tabela-dados">
                        <div className="cabecalho-dados">
                            <div className="column-45">Nome</div>
                            <div className="column-50">E-mail</div>
                        </div>
                        <div className="corpo-dados corpo-corretores">
                            { usuariosInstrutor.length === 0 ? 
                                <div className="row-dados">
                                    <div className="column-zerado">
                                        Não foi encontrado nenhum resultado.
                                    </div>
                                </div>
                                : (
                                    (usuariosInstrutor.slice(0).reverse().map((users) => (
                                        <Fragment>
                                            <div key={users._id} className="row-dados">
                                                <div className="column-45">
                                                    {users.name}
                                                </div>
                                                <div className="column-50">
                                                    {users.email}
                                                </div>
                                                <div className="column-icon">
                                                    <FontAwesomeIcon icon={faTrashCan}  onClick={() => removeCorretor(users._id)}/>
                                                </div>
                                            </div>
                                        </Fragment>
                                    )))
                                )
                            }
                            <h5>Exibindo {usuariosInstrutor.length} {usuariosInstrutor.length === 1 ? "resultado" : "resultados"}</h5>
                        </div>
                    </div>
                </div>
            </main>
            {modalIsOpen ? (
                <Modal titulo="Novo Instrutor" onClose={() => {setModalIsOpen(false)}}>
                    <div className='tabela-users'>
                        <div className='cabecalho-tabela'>
                            <div className="column-nome">
                                Nome
                            </div>
                            <div className="column-email">
                                Email
                            </div>
                        </div>
                        {users?.filter(({ excluido }) => excluido === false).filter(({ instrutor }) => instrutor === false).slice(0).map((users) => (
                            <div key={users._id} className="row-tabela">
                                <div className="column-nome">
                                    {users.name}
                                </div>
                                <div className="column-email">
                                    {users.email}
                                </div>
                                <div className="column-add">
                                    <FontAwesomeIcon icon={faUserPlus}  onClick={() => adicionarCorretor(users._id)}/>
                                </div>
                            </div>
                        ))}
                    </div>
                </Modal>
            ): null}
        </Fragment>
    )
};