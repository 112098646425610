import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import Moment from 'react-moment';
import 'moment-timezone';
import "../../../styles/log.scss";
import "../../../styles/reset.scss";
import Api from '../../../services/api';
import Loading from '../../layouts/loading';
import UsersService from '../../../services/users';

export default function ContentAdministracaoRegistros() {
    const navigate = useNavigate();
    const [registros, setRegistros] = useState([]);
    const [permission, setPermission] = useState();
    const [finds, setFinds] = useState("");

    async function getPermission() {
        const res = await UsersService.logado();
        setPermission(res.data.user.permission);
    }

    useEffect(() => {
        Api.get('/registros').then(({ data }) => {
            setRegistros(data);
        });
        getPermission();
    }, []);
    
    async function deleteAll() {
        Api.delete('/registros').then(() => {
            window.location.reload()
        });
    };

    function busca(a) {
        if (finds === "") {
            return a
        } else {
            return a.name.toLowerCase().includes(finds.toLowerCase()) || a.link.toLowerCase().includes(finds.toLowerCase()) || a.data.toLowerCase().includes(finds.toLowerCase())
        }
    }

    document.title = `Registros - Centro de Treinamento`;

    return(
        <Fragment>
            { registros !== "" ? 
            <main>
                <div className="conteudo-log">
                    <div className="cabecalho-conteudo">
                        <h2>Registros</h2>
                        <div className="buttons-conteudo">
                            <button className="button-red" onClick={()=> navigate(`/administracao`)}>Voltar</button>
                            <button className="button-blue" onClick={() => {window.print()}}>Imprimir</button>
                            {permission === 1 && <button className="button-darkred" onClick={() => {deleteAll()}}>Deletar Registros</button>}
                        </div>
                    </div>
                    <div className='container-search'>
                        <input type='text' placeholder='Buscar' onChange={e => {setFinds(e.target.value)}}/>
                        <FontAwesomeIcon icon={faSearch}/>
                    </div>
                    <div className="tabela-dados">
                        <div className="cabecalho-dados">
                            <div className="column-10">Data</div>
                            <div className="column-10">Usuário</div>
                            <div className="column-75">Registro</div>
                        </div>
                        <div className="corpo-dados corpo-corretores">
                            { registros.filter(busca).length === 0 ? 
                                <div className="row-dados">
                                    <div className="column-zerado">
                                        Não foi encontrado nenhum resultado.
                                    </div>
                                </div>
                            : 
                                registros.slice().reverse().filter(busca).map((item, index) => (
                                    <div key={index} className="row-dados">
                                        <div className="column-10">
                                            <Moment format='DD/MM/YY [-] HH:mm'>{item.data}</Moment>
                                        </div>
                                        <div className="column-10">
                                            {item.name}
                                        </div>
                                        { item.titulo ? <div className="column-20" style={{cursor: 'copy'}} onClick={() => navigator.clipboard.writeText(item.titulo)}>
                                                {item.titulo}
                                            </div>
                                        :
                                            <div className="column-20">
                                                --
                                            </div>
                                        }
                                        <div className="column-60" style={{cursor: 'copy'}} onClick={() => navigator.clipboard.writeText(item.link)}>
                                            {item.link}
                                        </div>
                                    </div>
                                ))
                            }
                            <h5>Exibindo {registros.length} {registros.length === 1 ? "resultado" : "resultados"}</h5>
                        </div>
                    </div>
                </div>
            </main>
            :
            <Loading/>
            }
        </Fragment>
    )
};