import { Fragment } from 'react';
import Header from '../../components/header/index';
import ContentAlunos from '../../components/alunos';

const AlunosScreen = (props) => (
  <Fragment>
    <Header ativo={props.ativo}/>
    <ContentAlunos/>
  </Fragment>
)

export default AlunosScreen;