import React, { Fragment, useEffect, useState } from 'react';
import Moment from 'react-moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import 'moment-timezone';
import 'moment/locale/pt-br'
import "../../styles/empresas.scss";
import "../../styles/cursos.scss";
import "../../styles/reset.scss";
import CursosService from '../../services/cursos';
import UsersService from '../../services/users';
import Modal from "../layouts/modal";
import ModalDangerSmall from '../layouts/modalDangerSmall';
import Loading from '../layouts/loading';
import { faMinus, faPlus, faSearch, faXmark } from '@fortawesome/free-solid-svg-icons';
Moment.globalLocale = 'pt-BR'

export default function ContentCursos() {
    const [cursos, setCursos] = useState([]);
    const [permission, setPermission] = useState();
    const [cursoAtivo, setCursoAtivo] = useState();
    const [carregado, setCarregado] = useState(false);
    const [modalCriarCurso, setModalCriarCurso] = useState(false);
    const [modalCurso, setModalCurso] = useState(false);
    const [modalEditarCurso, setModalEditarCurso] = useState(false);
    const [modalExcluirCurso, setModalExcluirCurso] = useState(false);
    const [finds, setFinds] = useState("");
    const [quantidadeConteudo, setQuantidadeConteudo] = useState(0);
    const [conteudosCurso, setConteudosCurso] = useState(["0"]);
    const [conteudosCursoAtivo, setConteudosCursoAtivo] = useState([""]);

    async function getCursos() {
        const response = await CursosService.index();
        setCursos(response.data);
    }

    function removerConteudo() {
        const remocao = [...conteudosCurso];
        remocao.pop();
        setQuantidadeConteudo(quantidadeConteudo - 1)
        setConteudosCurso(remocao);
    }

    function adicionarConteudo() {
        setConteudosCurso([ ...conteudosCurso, `${quantidadeConteudo + 1}`]);
        setQuantidadeConteudo(quantidadeConteudo + 1)
    }

    async function createCurso(evt) {
        evt.preventDefault();

        const formData = new FormData(evt.target)
        let form = Object.fromEntries(formData);

        form.valor = { socio: form.socio, nao_socio: form.nao_socio};

        delete form.socio;
        delete form.nao_socio;

        form.conteudo_programatico = [];

        for (let i = 0; i < conteudosCurso.length; i++) {
            const conteudoInput = document.querySelector(`input[name^="conteudo${i}"]`);

            const dat = conteudoInput.value

            form.conteudo_programatico.push(dat);
        };

        for (let propriedade in form) {
            if (/^conteudo\d+$/.test(propriedade)) {
                delete form[propriedade];
            }
        }

        try {
            await CursosService.create(form);
            await getCursos();
            setModalCriarCurso(false);
        } catch (error) {
            console.log(error);
        }
    }

    async function editCurso(evt) {
        evt.preventDefault();

        const formData = new FormData(evt.target)
        let form = Object.fromEntries(formData);

        form.valor = { socio: form.socio, nao_socio: form.nao_socio};

        delete form.socio;
        delete form.nao_socio;

        form.info_complementar === "" && delete form.info_complementar;

        form.conteudo_programatico = [];

        for (let i = 0; i < conteudosCursoAtivo.length; i++) {
            const conteudoInput = document.querySelector(`input[name^="conteudoAtivo${i}"]`);

            const dat = conteudoInput.value

            form.conteudo_programatico.push(dat);
        };

        for (let propriedade in form) {
            if (/^conteudoAtivo\d+$/.test(propriedade)) {
                delete form[propriedade];
            }
        }

        try {
            await CursosService.editar(cursoAtivo._id, form);
            await getCursos();
            setModalEditarCurso(false);
        } catch (error) {
            console.log(error);
        }
    }

    async function deleteCurso() {
        await CursosService.desativar_curso(cursoAtivo._id);
        setModalExcluirCurso(false);
        getCursos();
    }

    useEffect(() =>{
        (async function() {
            const response_user = await UsersService.logado();
            setPermission(response_user.data.user.permission);
            await getCursos();
            setCarregado(true)
        })();
    },[]);

    // FILTRO
    function busca(a) {
        if (finds === "") {
            return a
        } else {
            return a.titulo.toLowerCase().includes(finds.toLowerCase()) || a.descritivo.toLowerCase().includes(finds.toLowerCase()) || a.tipo_formacao.toLowerCase().includes(finds.toLowerCase()) || a.info_complementar.toLowerCase().includes(finds.toLowerCase());
        }
    }
    
    // ORDENAR ALFABÉTICA
    function ordemAlfabetica(a, b){
        return a.titulo.replace(/\s/g, '') < b.titulo.replace(/\s/g, '') ? -1 : a.titulo.replace(/\s/g, '') > b.titulo.replace(/\s/g, '') ? 1 : 0;
    }
    
    document.title = `Cursos - Centro de Treinamento`;

    return(
        <Fragment>
            { carregado ?
                <main className='main'>
                    <div className="conteudo-cursos">
                        <div className="cabecalho-conteudo">
                            <h2>Cursos</h2>
                            <div className="buttons-conteudo">
                                <button className="button-blue" onClick={() => {window.print()}}>Imprimir</button>
                                <button className="button-green" onClick={() => {setModalCriarCurso(true)}}>Novo Curso</button>
                            </div>
                        </div>
                        <div className='container-search'>
                            <input type='text' placeholder='Buscar' onChange={e => {setFinds(e.target.value)}}/>
                            <FontAwesomeIcon icon={faSearch}/>
                        </div>
                        <div className='lista-cursos'>
                            { cursos?.filter(({excluido}) => excluido === false).filter(busca).sort(ordemAlfabetica).map((item, index) => (
                                <div key={index} className='item-curso' onClick={() => {setCursoAtivo(item); setModalCurso(true)}}>
                                    <div className='dados-curso'>
                                        <div className='titulo-curso'>
                                            <h3>{item.titulo}</h3>
                                            { item.info_complementar ? <span>{item.info_complementar}</span> : null}
                                        </div>
                                        <div className='info-curso'>
                                            <p>Tipo de Curso: {item.tipo_formacao}</p>
                                            <p>Carga Horária: {item.carga_horaria}h</p>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div className='valores-curso'>
                                        <div className='item-valor-curso'>
                                            <h4>Não Sócio</h4>
                                            <p>R${item.valor.nao_socio}</p>
                                        </div>
                                        <div className='item-valor-curso'>
                                            <h4>Sócio</h4>
                                            <p>R${item.valor.socio}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </main>
            : 
            <Loading>
                
            </Loading>
            }
            {modalCriarCurso ? (
                <Modal titulo="Novo Curso" width="50%" onClose={() => {setModalCriarCurso(false)}}>
                    <form onSubmit={createCurso}>
                        <div id="campos-novo-curso">
                            <div className="campo-form campo-45">
                                <label htmlFor="titulo">Título</label>
                                <input type="text" name="titulo" id="titulo" required autoComplete='off'/>
                            </div>
                            <div className="campo-form campo-35">
                                <label htmlFor="info_complementar">Informação complementar</label>
                                <input type="text" name="info_complementar" id="info_complementar" placeholder='Ex: Inflamáveis' autoComplete='off'/>
                            </div>
                            <div className="campo-form campo-20">
                                <label htmlFor="carga_horaria">Carga horária</label>
                                <input type="number" name="carga_horaria" id="carga_horaria" required autoComplete='off'/>
                            </div>
                            <div className="campo-form campo-65">
                                <label htmlFor="descritivo">Descritivo</label>
                                <input type="text" name="descritivo" id="descritivo" required placeholder='Ex: Trabalho em Altura, Espaço Confinado' autoComplete='off'/>
                            </div>
                            <div className='campo-form campo-35'>
                                <label htmlFor="tipo_formacao">Tipo de Curso</label>
                                <select name='tipo_formacao' id='tipo_formacao' defaultValue="default" required>
                                    <option value="default" disabled>Selecione</option>
                                    <option value="Formação">Formação</option>
                                    <option value="Reciclagem">Reciclagem</option>
                                    <option value="Instrutor">Instrutor</option>
                                </select>
                            </div>
                            <div className="campo-form campo-35">
                                <label htmlFor="socio">Valor de associado</label>
                                <input type="number" name="socio" id="socio" required autoComplete='off'/>
                            </div>
                            <div className="campo-form campo-35">
                                <label htmlFor="nao_socio">Valor de não associado</label>
                                <input type="number" name="nao_socio" id="nao_socio" required autoComplete='off'/>
                            </div>
                            <div className="campo-form campo-30">
                                <label htmlFor="validade_certificado">Validade Certificado</label>
                                <input type="number" name="validade_certificado" id="validade_certificado" placeholder='Em meses' required autoComplete='off'/>
                            </div>
                            <h5>Conteúdo Programático <button className='button-green' type='button' onClick={() => {adicionarConteudo()}}><FontAwesomeIcon icon={faPlus}/></button> { conteudosCurso.length !== 1 ? <button className='button-red' type='button' onClick={() => {removerConteudo()}}><FontAwesomeIcon icon={faMinus}/></button> : <button className='button-red button-disabled' type='button'><FontAwesomeIcon icon={faMinus}/></button>} </h5>
                            {conteudosCurso.map((item, index) => (
                                <div className={`container-inputs`} key={index}>
                                    <div className="campo-form campo-100">
                                        <input type="text" name={`conteudo${item}`} id={`conteudo${item}`} autoComplete={"off"}/>
                                    </div>
                                </div>
                            ))}
                            {/* <div className="campo-form campo-100">
                                <label htmlFor="conteudo_programatico">Conteúdo Programático (Separar por ponto e virgula):</label>
                                <textarea type="text" name="conteudo_programatico" id="conteudo_programatico" rows="5"/>
                            </div> */}
                        </div>
                        <div className='buttons-form'>
                            <button type="reset" className="button-red" onClick={() => {setModalCriarCurso(false)}}>Cancelar</button>
                            <button type="submit" className="button-green">Cadastrar</button>
                        </div>
                    </form>
                </Modal>
            ): null}
            {modalCurso ? (
                <Modal titulo={`${cursoAtivo.titulo} / ${cursoAtivo.tipo_formacao} ${cursoAtivo.info_complementar !== "" ? `(${cursoAtivo.info_complementar})` : ""}`} width="50%" onClose={() => {setModalCurso(false)}}>
                    <div className="info-modal modal-curso">
                        <div className="campo-info campo-80">
                            <h4>Descritivo:</h4>
                            <h5>{cursoAtivo.descritivo}</h5>
                        </div>
                        <div className="campo-info campo-20">
                            <h4>Carga horária:</h4>
                            <h5>{cursoAtivo.carga_horaria}h</h5>
                        </div>
                        <div className="campo-info campo-35">
                            <h4>Valor de associado:</h4>
                            <h5>R${cursoAtivo.valor.socio}</h5>
                        </div>
                        <div className="campo-info campo-35">
                            <h4>Valor de não associado:</h4>
                            <h5>R${cursoAtivo.valor.nao_socio}</h5>
                        </div>
                        <div className="campo-info campo-30">
                            <h4>Validade Certificado:</h4>
                            <h5>{cursoAtivo.validade_certificado} meses</h5>
                        </div>
                        <div className="campo-info campo-100">
                            <h4>Conteúdo Programático:</h4>
                            <h5>
                                <ul>
                                    {cursoAtivo.conteudo_programatico.map((item, index) => (
                                        <li key={index}>{item}</li>
                                    ))}
                                </ul>
                            </h5>
                        </div>
                    </div>
                    <div className='buttons-info'>
                        <button type="reset" className="button-red" onClick={() => {setModalCurso(false)}}>Cancelar</button>
                        <button type="button" className="button-blue" onClick={() => {setModalCurso(false); setModalEditarCurso(true); setConteudosCursoAtivo(cursoAtivo.conteudo_programatico); setQuantidadeConteudo(cursoAtivo.conteudo_programatico.length)}}>Editar</button>
                        { permission === 1 ? <button type="button" className="button-darkred" onClick={() => {setModalCurso(false); setModalExcluirCurso(true)}}>Excluir</button> : null}
                    </div>
                </Modal>
            ): null}
            {modalEditarCurso ? (
                <Modal titulo="Editar Curso" width="50%" onClose={() => {setModalEditarCurso(false)}}>
                    <form onSubmit={editCurso}>
                        <div id="campos-editar-curso">
                            <div className="campo-form campo-45">
                                <label htmlFor="titulo">Título</label>
                                <input type="text" name="titulo" id="titulo" required autoComplete='off' defaultValue={cursoAtivo.titulo}/>
                            </div>
                            <div className="campo-form campo-35">
                                <label htmlFor="info_complementar">Informação complementar</label>
                                <input type="text" name="info_complementar" id="info_complementar" placeholder='Ex: Inflamáveis' autoComplete='off' defaultValue={cursoAtivo.info_complementar}/>
                            </div>
                            <div className="campo-form campo-20">
                                <label htmlFor="carga_horaria">Carga horária</label>
                                <input type="number" name="carga_horaria" id="carga_horaria" required autoComplete='off' defaultValue={cursoAtivo.carga_horaria}/>
                            </div>
                            <div className="campo-form campo-65">
                                <label htmlFor="descritivo">Descritivo</label>
                                <input type="text" name="descritivo" id="descritivo" required placeholder='Ex: Trabalho em Altura, Espaço Confinado' autoComplete='off' defaultValue={cursoAtivo.descritivo}/>
                            </div>
                            <div className='campo-form campo-35'>
                                <label htmlFor="tipo_formacao">Tipo de Curso</label>
                                <select name='tipo_formacao' id='tipo_formacao' defaultValue={cursoAtivo.tipo_formacao} required>
                                    <option value="Formação">Formação</option>
                                    <option value="Reciclagem">Reciclagem</option>
                                    <option value="Instrutor">Instrutor</option>
                                </select>
                            </div>
                            <div className="campo-form campo-35">
                                <label htmlFor="socio">Valor de associado</label>
                                <input type="number" name="socio" id="socio" required autoComplete='off' defaultValue={cursoAtivo.valor.socio}/>
                            </div>
                            <div className="campo-form campo-35">
                                <label htmlFor="nao_socio">Valor de não associado</label>
                                <input type="number" name="nao_socio" id="nao_socio" required autoComplete='off' defaultValue={cursoAtivo.valor.nao_socio}/>
                            </div>
                            <div className="campo-form campo-30">
                                <label htmlFor="validade_certificado">Validade Certificado</label>
                                <input type="number" name="validade_certificado" id="validade_certificado" placeholder='Em meses' required autoComplete='off' defaultValue={cursoAtivo.validade_certificado}/>
                            </div>
                            <h5>Conteúdo Programático <button className='button-green' type='button' onClick={() => {setConteudosCursoAtivo([ ...conteudosCursoAtivo, ``])}}><FontAwesomeIcon icon={faPlus}/></button> </h5>
                            {conteudosCursoAtivo.map((item, index) => (
                                <div className={`container-inputs`} key={index}>
                                    <div className="campo-form campo-100">
                                        <input type="text" minLength={5} value={item} name={`conteudoAtivo${index}`} autoComplete='off' onChange={(e) => { const newConteudos = [...conteudosCursoAtivo]; newConteudos[index] = e.target.value; setConteudosCursoAtivo(newConteudos); }} />
                                        {conteudosCursoAtivo.length === 1 ? <button className='button-disabled'><FontAwesomeIcon icon={faXmark}/></button> : <button onClick={() => { const newConteudos = [...conteudosCursoAtivo]; newConteudos.splice(index, 1); setConteudosCursoAtivo(newConteudos); }}><FontAwesomeIcon icon={faXmark}/></button>}
                                        {/* <input type="text" name={`conteudo${index}`} id={`conteudo${index}`} defaultValue={item} autoComplete={"off"} onChange={() => {document.getElementById(`conteudo${index}`).value = index}}/>
                                        <button onClick={() => {document.getElementById(`conteudo${index}`).value = conteudosCurso[index]; removerData(item);}}>X</button> */}
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className='buttons-form'>
                            <button type="reset" className="button-red" onClick={() => {setModalEditarCurso(false); setModalCurso(true)}}>Cancelar</button>
                            <button type="submit" className="button-green">Salvar</button>
                        </div>
                    </form>
                </Modal>
            ): null}
            {modalExcluirCurso ? (
                <ModalDangerSmall mensagem={['Deseja excluir definitivamente o curso', <br/>,<span>{cursoAtivo.titulo} / {cursoAtivo.tipo_formacao} {cursoAtivo.info_complementar !== "" ? `(${cursoAtivo.info_complementar})` : ""}</span>,"?"]} onClose={() => {setModalExcluirCurso(false)}}>
                    <div className='buttons-form'>
                        <button type="reset" className="button-red" onClick={() => {setModalExcluirCurso(false)}}>Cancelar</button>
                        <button type="submit" className="button-blue" onClick={() => {deleteCurso()}}>Excluir</button>
                    </div>
                </ModalDangerSmall>
            ): null}
        </Fragment>
    )
};