import React, { Fragment, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import Moment from 'react-moment';
import 'moment-timezone';
import "../../styles/alunos.scss";
import "../../styles/reset.scss";
import Modal from "../layouts/modal";
import AlunosService from '../../services/alunos';
import Loading from '../layouts/loading';

export default function ContentAdministracaoEmpresas() {
    const [alunos, setAlunos] = useState([]);
    const [alunoAtivo, setAlunoAtivo] = useState([]);
    const [carregado, setCarregado] = useState(false);
    const [modalAluno, setModalAluno] = useState(false);
    const [modalEditarAluno, setModalEditarAluno] = useState(false);
    const [finds, setFinds] = useState("");

    async function getAlunos() {
        const data = await AlunosService.index();
        setAlunos(data.data); 
    };

    useEffect(() => {
        (async () => {
            await getAlunos();
            setCarregado(true);
        })();
    }, []);

    async function editAluno(event) {
        event.preventDefault();

        const formData = new FormData(event.target);
        let form = Object.fromEntries(formData);

        form.nome = form.nome.toUpperCase();

        try {
            await AlunosService.editar(alunoAtivo._id, form)
            getAlunos();
            setModalEditarAluno(false);
        } catch (error) {
            console.log(error)
        }
    };

    // FILTRO
    function busca(a) {
        if (finds === "") {
            return a
        } else {
            return a.nome.toLowerCase().includes(finds.toLowerCase()) || a.cpf.toLowerCase().includes(finds.toLowerCase());
        }
    }

    function ordemAlfabetica(a, b) {
        return a.nome > b.nome ? -1 : a.nome < b.nome ? 1 : 0;
    }
    
    const alunosAtivos = alunos?.filter(({ excluido }) => excluido === false);
    
    document.title = `Alunos - Centro de Treinamento`;

    return(
        <Fragment>
            { carregado ?
                <main id='main-alunos'>
                <div className="conteudo-alunos">
                    <div className="cabecalho-conteudo">
                        <h2>Alunos</h2>
                        <div className="buttons-conteudo">
                            {/* <button className="button-red" onClick={()=> navigate(`/administracao`)}>Voltar</button> */}
                            <button className="button-blue">Imprimir</button>
                            {/* <button className="button-green add-sinistro" onClick={()=> setModalNovaEmpresa(true)}>Novo aluno</button> */}
                        </div>
                    </div>
                    <div className='container-search'>
                        <input type='text' placeholder='Buscar' onChange={e => {setFinds(e.target.value)}}/>
                        <FontAwesomeIcon icon={faSearch}/>
                    </div>
                    <div className="tabela-dados">
                        <div className="cabecalho-dados">
                            <div className="column-35">Nome</div>
                            <div className="column-25">CPF</div>
                            <div className="column-30">Quantidade de Treinamentos</div>
                            <div className="column-10">Cadastro</div>
                        </div>
                        <div className="corpo-dados corpo-empresas">
                            { alunosAtivos.length === 0 ? 
                                <div className="row-dados">
                                    <div className="column-zerado">
                                        Não foi encontrado nenhum resultado.
                                    </div>
                                </div>
                                : (
                                    (alunosAtivos.sort(ordemAlfabetica).filter(busca).slice(0).reverse().map((item, index) => (
                                        <div key={index} className="row-dados" onClick={() => {setModalAluno(true); setAlunoAtivo(item)}}>
                                            <div className="column-35">
                                                {item.nome}
                                            </div>
                                            <div className="column-25">
                                                {item.cpf}
                                            </div>
                                            <div className="column-30">
                                                {item.treinamentos.length}
                                            </div>
                                            <div className="column-10">
                                                <Moment format="DD/MM/YYYY">
                                                    {item.created_at}
                                                </Moment>
                                            </div>
                                        </div>
                                    )))
                                )
                            }
                            <h5>Exibindo {alunosAtivos.filter(busca).length} {alunosAtivos.filter(busca).length === 1 ? "resultado" : "resultados"}</h5>
                        </div>
                    </div>
                </div>
                </main>
            :
                <Loading/>
            }
            {modalAluno ? (
                <Modal titulo="Informações do Aluno" onClose={() => {setModalAluno(false);}}>
                    <div className="info-modal modal-aluno">
                        <div className="campo-info campo-20">
                            <h4>CPF:</h4>
                            <h5>{alunoAtivo.cpf}</h5>
                        </div>
                        <div className="campo-info campo-80">
                            <h4>Nome:</h4>
                            <h5>{alunoAtivo.nome}</h5>
                        </div>
                        <div className='tabela-dados'>
                            <div className='cabecalho-dados'>
                                <div className='column-70'>Treinamento</div>
                                <div className='column-30'>Situação</div>
                            </div>
                            <ul className='corpo-dados'>
                                { alunoAtivo.treinamentos.length === 0 ? 
                                    <p className='no-info'>Esse aluno ainda não realizou nenhum treinamento.</p>
                                : 
                                    alunoAtivo.treinamentos.map((item, index) => (
                                        <li key={index} className='row-dados' onClick={() => window.open(`https://sistema.ctcpp.com.br/treinamento/${item.treinamento._id}`, '_blank')}>
                                            <div className='column-70'>{item.treinamento.titulo}</div>
                                            <div className='column-30'>{item.concluido ? "Concluido" : "Não Concluido"}</div>
                                        </li>
                                    )
                                )}
                            </ul>
                        </div>
                    </div>
                    <div className='buttons-info'>
                        <button type="reset" className="button-red" onClick={() => {setModalAluno(false)}}>Cancelar</button>
                        <button type="button" className="button-blue" onClick={() => {setModalAluno(false); setModalEditarAluno(true)}}>Editar</button>
                    </div>
                </Modal>
            ): null}
            {modalEditarAluno ? (
                <Modal titulo="Editar Aluno" onClose={() => {setModalEditarAluno(false)}}>
                    <form onSubmit={editAluno}>
                        <div id="campos-nova-agenda">
                            <div className="campo-form campo-55">
                                <label htmlFor="nome">Nome Completo</label>
                                <input type="text" name="nome" id="nome" defaultValue={alunoAtivo.nome}/>
                            </div>
                            <div className="campo-form campo-45">
                                <label htmlFor="cpf">CPF</label>
                                <input type="text" name="cpf" id="cpf" defaultValue={alunoAtivo.cpf}/>
                            </div>
                        </div>
                        <div className='buttons-form'>
                            <button type="reset" className="button-red" onClick={() => {setModalEditarAluno(false); setModalAluno(true)}}>Cancelar</button>
                            <button type="submit" className="button-green">Salvar</button>
                        </div>
                    </form>
                </Modal>
            ): null}
        </Fragment>
    )
};