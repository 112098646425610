import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPerson, faPlus, faMinus, faSearch, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { faCircleCheck, faCircleXmark, faCircle, faClock, faCalendar } from '@fortawesome/free-regular-svg-icons'
import Moment from 'react-moment';
import 'moment-timezone';
import 'moment/locale/pt-br'
import moment from 'moment/moment';
import "../../styles/empresas.scss";
import "../../styles/treinamentos.scss";
import "../../styles/reset.scss";
import UsersService from '../../services/users';
import TreinamentosService from '../../services/treinamentos';
import LocaisService from '../../services/locais';
import CursosService from '../../services/cursos';
import EmpresasService from '../../services/empresas';
import Modal from "../layouts/modal";
import Loading from '../layouts/loading';
Moment.globalLocale = 'pt-BR'

export default function ContentTreinamentos() {
    const navigate = useNavigate();
    const [treinamentos, setTreinamentos] = useState([]);
    const [cursos, setCursos] = useState([]);
    const [instrutores, setInstrutores] = useState();
    const [locais, setLocais] = useState();
    const [empresas, setEmpresas] = useState([]);
    const [carregandoNovos, setCarregandoNovos] = useState(false);
    const [aguardandoRetorno, setAguardandoRetorno] = useState(false);
    const [modalCriarTreinamento, setModalCriarTreinamento] = useState(false);
    const [empresaTreinamento, setEmpresaTreinamento] = useState(false);
    const [quantidadeDatas, setQuantidadeDatas] = useState(0);
    const [datasTreinamento, setDatasTreinamento] = useState(["0"]);
    const [filtroSelecionado, setFiltroSelecionado] = useState(localStorage.getItem('filtro-ativo-treinamentos') || 'n_finalizados');
    const [finds, setFinds] = useState("");
    const [currentPage, setCurrentPage] = useState(0);
    const [carregado, setCarregado] = useState(false);

    function removerData() {
        const remocao = [...datasTreinamento];
        remocao.pop();
        setQuantidadeDatas(quantidadeDatas - 1)
        setDatasTreinamento(remocao);
    }

    function adicionarData() {
        setDatasTreinamento([ ...datasTreinamento, `${quantidadeDatas + 1}`]);
        setQuantidadeDatas(quantidadeDatas + 1);
    }

    async function getLocais() {
        const response = await LocaisService.index();
        setLocais(response.data);
    }

    async function getCursos() {
        const response = await CursosService.index();
        setCursos(response.data);
    }

    async function getInstrutores() {
        const response = await UsersService.index();
        setInstrutores(response.data.user.filter(({ instrutor }) => instrutor === true));
    }

    async function getEmpresas() {
        const response = await EmpresasService.index();
        setEmpresas(response.data);
    }

    async function getTreinamentos(){
        const response = await TreinamentosService.index();
        setTreinamentos(response.data)
    }

    async function createTreinamento(evt) {
        evt.preventDefault();

        const formData = new FormData(evt.target)
        let form = Object.fromEntries(formData);

        form.datas = [];

        const response_curso = await CursosService.curso(form.curso);
        const response_user = await UsersService.usuario(form.instrutor);

        form.dados_curso ={
            curso: form.curso,
            conteudo_programatico: response_curso.data.conteudo_programatico,
            titulo: response_curso.data.titulo,
            descritivo: response_curso.data.descritivo,
            carga_horaria: response_curso.data.carga_horaria,
            tipo_formacao: response_curso.data.tipo_formacao,
            info_complementar: response_curso.data.info_complementar,
            validade_certificado: response_curso.data.validade_certificado
        };

        form.dados_instrutor ={
            instrutor: form.instrutor,
            nome: response_user.data.name,
            funcao: response_user.data.funcoes
        };
        
        delete form.curso;
        delete form.instrutor;
        delete form.vincular_empresa;

        for (let i = 0; i < datasTreinamento.length; i++) {
            let dataInput = document.querySelector(`input[name^="data${i}"]`).value;
            const duracaoInput = document.querySelector(`input[name="duracao${i}"]`);

            const dat = {
                dia: moment(dataInput).add(3, 'h'),
                duracao: duracaoInput.value
            }

            form.datas.push(dat);
        };

        for (let propriedade in form) {
            if (/^data\d+$/.test(propriedade)) {
                delete form[propriedade];
            }

            if (propriedade.startsWith("duracao")) {
              delete form[propriedade];
            }
        }

        try {
            setAguardandoRetorno(true);
            await TreinamentosService.create(form);
            window.location.reload();
        } catch (error) {
            console.log(error);
        }
    }

    // useEffect(() => {
    //     (async function() {
    //         setCarregandoNovos(true);
    //         const response = await TreinamentosService.index();
    //         setTreinamentos((prevTreinamentos) => [...prevTreinamentos, ...response.data]);
    //         setCarregandoNovos(false);
    //     })();
    // }, [currentPage]);

    useEffect(() => {
        const handleScroll = () => {
            if (
              window.innerHeight + document.documentElement.scrollTop ===
              document.documentElement.offsetHeight
            ) {
              setCurrentPage((paginaAtual) => paginaAtual + 1);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
        window.removeEventListener('scroll', handleScroll);
        };
      }, []);

    useEffect(() =>{
        (async function() {
            await getLocais();
            await getInstrutores();
            await getCursos();
            await getEmpresas();
            await getTreinamentos();
            setCarregado(true);
        })();
    },[]);

    // FILTRO
    function filtro(a) {
        switch (true) {
            case (filtroSelecionado === "finalizados"):
                return a.status.at(-1).situacao === "Finalizado";
            case (filtroSelecionado === "n_finalizados"):
                return a.status.at(-1).situacao !== "Finalizado";
            case (filtroSelecionado === "todos"):
                return a;
            default:
            break;
        }
    }

    function busca(a) {
        if (finds === "") {
            return a
        } else {
            return a.titulo.toLowerCase().includes(finds.toLowerCase()) || a.dados_curso.descritivo.toLowerCase().includes(finds.toLowerCase()) || a.dados_curso.tipo_formacao.toLowerCase().includes(finds.toLowerCase()) || a.dados_instrutor.nome.toLowerCase().includes(finds.toLowerCase())
        }
    }

    // ORDENAR ALFABÉTICA
    function ordemAlfabetica(a, b){
        return a.titulo.replace(/\s/g, '') < b.titulo.replace(/\s/g, '') ? -1 : a.titulo.replace(/\s/g, '') > b.titulo.replace(/\s/g, '') ? 1 : 0;
    }

    document.title = `Treinamentos - Centro de Treinamento`;

    return(
        <Fragment>
            { carregado ?
            <main className='main'>
                <div className="conteudo-treinamentos">
                    <div className="cabecalho-conteudo">
                        <h2>Treinamentos</h2>
                        <div className="buttons-conteudo">
                            <button className="button-blue" onClick={() => {window.print()}}>Imprimir</button>
                            <button className="button-green" onClick={() => setModalCriarTreinamento(true)}>Novo Treinamento</button>
                        </div>
                    </div>
                    <div className='container-search'>
                        <select defaultValue={filtroSelecionado} onChange={e => {setFiltroSelecionado(e.target.value); localStorage.setItem('filtro-ativo-treinamentos', e.target.value)}}>
                            <option value="todos">Todos</option>
                            <option value="finalizados">Finalizados</option>
                            <option value="n_finalizados">Não Finalizados</option>
                        </select>
                        <input type='text' placeholder='Buscar' onChange={e => {setFinds(e.target.value)}}/>
                        <FontAwesomeIcon icon={faSearch}/>
                    </div>
                    <div className='lista-treinamentos'>
                        { treinamentos?.filter(filtro).filter(busca).filter(({excluido}) => excluido === false).map((item, index) => (
                            <div className='item-treinamento' key={index} onClick={()=> navigate(`/treinamento/${item._id}`)}>
                                <div className='informacoes-treinamento'>
                                    <div className='titulo-treinamento'>
                                        <h3>
                                            {item.status.at(-1).situacao === "Finalizado" ?
                                                <FontAwesomeIcon icon={faCircleCheck}/>
                                            :
                                                item.status.at(-1).situacao === "Cancelado" ?
                                                    <FontAwesomeIcon icon={faCircleXmark}/>
                                                :
                                                    <FontAwesomeIcon icon={faCircle}/>
                                            }
                                            {item.titulo}
                                        </h3>
                                        <p>Instrutor: {item.dados_instrutor.nome}</p>
                                        <p>Carga Horária: {item.dados_curso.carga_horaria}h</p>
                                    </div>
                                    <div className='infos-treinamento'>
                                        <div className='item-infos-treinamento'>
                                            <FontAwesomeIcon icon={faCalendar} />
                                            <p><Moment format='DD'>{item.datas[0].dia}</Moment> de <Moment format='MMMM [de] YYYY'>{item.datas[0].dia}</Moment></p>
                                        </div>
                                        <div className='item-infos-treinamento'>
                                            <FontAwesomeIcon icon={faClock} />
                                            <p>{item.dados_curso.carga_horaria}h</p>
                                        </div>
                                        <div className='item-infos-treinamento'>
                                            <FontAwesomeIcon icon={faPerson} />
                                            <p>{item.alunos.length}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                { carregandoNovos ?
                    <div className='infinite-loader'>
                        <FontAwesomeIcon icon={faSpinner} spinPulse/>
                    </div>
                :
                    null
                }
            </main>
            :
            (
            <Loading>
                
            </Loading>
            )}
            {modalCriarTreinamento ? (
                <Modal titulo="Novo Treinamento" width="50%" onClose={() => {setModalCriarTreinamento(false); setEmpresaTreinamento(false)}}>
                    <form onSubmit={createTreinamento}>
                        <div id="campos-novo-treinamento">
                            <div className="campo-form campo-60">
                                <label htmlFor="titulo">Título</label>
                                <input type="titulo" name="titulo" id="titulo" required/>
                            </div>
                            <div className="campo-form campo-40">
                                <label htmlFor="curso">Curso</label>
                                <select name="curso" id="curso" defaultValue="" required>
                                    <option value="" disabled>Selecione</option>
                                    { cursos.sort(ordemAlfabetica).map((item, index) => (
                                        <option value={item._id} key={index}>{item.titulo} / {item.tipo_formacao} { item.info_complementar !== "" ? `(${item.info_complementar})` : null}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="campo-form campo-40">
                                <label htmlFor="local">Local</label>
                                <select name="local" id="local" defaultValue="" required>
                                    <option value="" disabled>Selecione</option>
                                    { locais.map((item, index) => (
                                        <option value={item._id} key={index}>{item.nome}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="campo-form campo-30">
                                <label htmlFor="instrutor">Instrutor</label>
                                <select name="instrutor" id="instrutor" defaultValue="" required>
                                    <option value="" disabled>Selecione</option>
                                    { instrutores.map((item, index) => (
                                        <option value={item._id} key={index}>{item.name}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="campo-form campo-30">
                                <label htmlFor="vincular_empresa">Vincular empresa</label>
                                <select name="vincular_empresa" id="vincular_empresa" defaultValue="" required onChange={(e) => {setEmpresaTreinamento(e.target.value)}}>
                                    <option value="" disabled>Selecione</option>
                                    <option value={true}>Sim</option>
                                    <option value={false}>Não</option>
                                </select>
                            </div>
                            { empresaTreinamento === "true" ? <div className="campo-form campo-100">
                                <label htmlFor="empresa">Selecione a empresa</label>
                                <select name="empresa" id="empresa" defaultValue="" required>
                                    <option value="" disabled>Selecione</option>
                                    { empresas.map((item, index) => (
                                        <option key={index} value={item._id}>{item.fantasia}</option>
                                    ))}
                                </select>
                            </div> 
                            : null }
                            <div className="campo-form campo-100">
                                <label htmlFor="descricao">Descrição:</label>
                                <textarea type="text" name="descricao" id="descricao" rows="3"/>
                            </div>
                            <h5>Dias de Treinamento <button className='button-green' type='button' onClick={() => {adicionarData()}}><FontAwesomeIcon icon={faPlus}/></button> { datasTreinamento.length !== 1 ? <button className='button-red' type='button' onClick={() => {removerData()}}><FontAwesomeIcon icon={faMinus}/></button> : <button className='button-red button-disabled' type='button'><FontAwesomeIcon icon={faMinus}/></button>} </h5>
                            {datasTreinamento.map((item, index) => (
                                <div className={`container-inputs`} key={index}>
                                    <div className="campo-form campo-50">
                                        <input type="date" name={`data${item}`} id={`data${item}`} required/>
                                    </div>
                                    <div className="campo-form campo-50">
                                        <input type="number" name={`duracao${item}`} id={`duracao${item}`} placeholder='Duração em horas' required/>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className='buttons-form'>
                            <button type="reset" className="button-red" onClick={() => {setModalCriarTreinamento(false); setEmpresaTreinamento(false)}}>Cancelar</button>
                            {aguardandoRetorno ? <button type="button" className="button-green">Aguarde<FontAwesomeIcon icon={faSpinner} spinPulse style={{marginLeft: ".5em"}}/></button> : <button type="submit" className="button-green">Cadastrar</button>}
                        </div>
                    </form>
                </Modal>
            ): null}
        </Fragment>
    )
};