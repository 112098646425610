import 'moment-timezone';
import moment from 'moment/moment';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

export default function GerarCertificado(treinamento, assinaturaPresidente, imageFundo, dias, aluno) {
    pdfMake.vfs = pdfFonts.pdfMake.vfs;

    const dadosConteudo = treinamento.dados_curso.conteudo_programatico.map((item) => {
        return[
            `${item}`,
        ]
    })

    const funcaoInstrutor = treinamento.dados_instrutor.funcao.map((item) => {
        return[
            `${item}`,
        ]
    });

    const details = [
        {
            width: 842,
            image: imageFundo,
            absolutePosition: {x:0, y:0},
        },
        // {
        //     columns: [
        //         {
        //             columns: [
        //                 {
        //                     image: logo,
        //                     width: 140
        //                 }
        //             ],
        //             width: '20%'
        //         },
        //         {
        //             width: '60%',
        //             height: 100,
        //             fontSize: 26,
        //             alignment: 'center',
        //             margin: [0, 50, 0, 0],
        //             text: 'CERTIFICADO',
        //             bold: true
        //         },
        //         // {
                    
        //         //     columns: [
        //         //         {
        //         //             margin: [0, 15, 0, 0],
        //         //             fit: 100,
        //         //             qr: `https://192.168.0.19:3000/certificado/${treinamento._id}`
        //         //         }
        //         //     ],
        //         //     width: '20%',
        //         //     alignment: 'right'
        //         // },
        //     ],
        //     margin: [0, 10, 0, 20]
        // },
        {
            text: `${treinamento.dados_curso.descritivo.toUpperCase()} / ${treinamento.dados_curso.titulo}`,
            alignment: 'center',
            fontSize: 20,
            margin: [0, 160, 0, 40],
            bold: true
        },
        {
            margin: [30, 0, 30, 0],
            lineHeight: 1.3,
            fontSize: 15,
            alignment: 'justify',
            text: `Que se confere a ${aluno.nome}, portador do CPF ${aluno.cpf}, ${treinamento.empresa ? `colaborador da empresa ${treinamento.empresa.razao_social}, CNPJ ${treinamento.empresa.cnpj},` : ""} concluiu com aprovação o curso de ${treinamento.dados_curso.tipo_formacao === "Formação" ? "" : treinamento.dados_curso.tipo_formacao.toUpperCase() + " de "}${treinamento.dados_curso.descritivo} em conformidade com a ${treinamento.dados_curso.titulo}, realizado no dia ${dias}, com carga horária de ${treinamento.dados_curso.carga_horaria}h, promovido pelo Centro de Treinamento e Capacitação Profissional do Paraná e realizado na cidade de ${treinamento.local.cidade} / ${treinamento.local.estado}.`
            // text: `Que se confere a ${treinamento.aluno}, portador do CPF ${treinamento.cpf}, concluiu com aprovação o curso de ${treinamento.curso.tipo_formacao === "Formação" ? "" : treinamento.curso.tipo_formacao.toUpperCase() + " de "}${treinamento.curso.descritivo} em conformidade com a ${treinamento.curso.titulo}, realizado nos dias ${dias}, com carga horária de ${treinamento.curso.carga_horaria}h, promovido pelo Centro de Treinamento e Capacitação Profissional do Paraná e realizado na cidade de ${treinamento.treinamento.local.cidade} / ${treinamento.treinamento.local.estado}.`
        },
        {
            margin: [0, 40, 0, 80],
            fontSize: 16,
            bold: true,
            alignment: 'center',
            text: `${treinamento.local.cidade}, ${moment(treinamento.datas.at(-1).dia).format("DD [de] MMMM [de] YYYY")}.`
            // text: `${treinamento.treinamento.local.cidade}, ${moment(treinamento.concluido_at).format('DD [de] MMMM [de] YYYY')}.`
        },
        {
            width: 150,
            image: assinaturaPresidente,
            absolutePosition: {x:100, y:420},
        },
        {
            canvas: [
                { type: 'line', x1: 5, y1: 5, x2: 250, y2: 5, lineWidth: 1 },
            ],
            absolutePosition: {x:50, y:480}
        },
        {
            text: `Jorge Pedro da Cruz Santos`,
            absolutePosition: {x:100, y:490}
        },
        {
            text: `Presidente`,
            absolutePosition: {x:150, y:505}
        },
        {
            text: `CTCPP`,
            absolutePosition: {x:160, y:520}
        },
        {
            canvas: [
                { type: 'line', x1: 5, y1: 5, x2: 250, y2: 5, lineWidth: 1 }, //Up line
            ],
            absolutePosition: {x:530, y:480}
        },
        {
            text: `${aluno.nome}`,
            alignment: 'center',
            width: 250,
            absolutePosition: {x:530, y:490}
        },
        {
            text: `CPF: ${aluno.cpf}`,
            alignment: 'center',
            width: 250,
            absolutePosition: {x:530, y:505}
        },
        {
            table: {
                headerRows: 1,
                widths: ['*'],
                body: [
                    [{text: `Conteúdo Programático`, alignment: 'center', fontSize: 14, bold: true, margin: [0, 5, 0, 5], border: [true, true, true, false]}],
                    [
                        {
                            ul: [
                                ...dadosConteudo
                            ],
                            lineHeight: 1.2,
                            fontSize: 11,
                            border: [true, false, true, true],
                            margin: [15, 10, 15, 15]
                        }
                    ]
                ]
            },
            margin: [0, 40, 0, 0],
            pageBreak: 'before'
        },
        {
            table: {
                widths: ['*'],
                body: [
                    [{text: `Este certificado tem a validade de ${treinamento.dados_curso.validade_certificado} meses (${treinamento.dados_curso.validade_certificado/12} ${treinamento.dados_curso.validade_certificado/12 === 1 ? 'ano' : 'anos'}) após a realização deste treinamento.`, alignment: 'center', fontSize: 12, bold: true, margin: [0, 5, 0, 5], border: [true, false, true, true]}],
                ]
            }
        },
        {
            table: {
                widths: ['50%', '50%'],
                body: [
                    [
                        {
                            table: {
                                widths: ['100%'],
                                body: [
                                    [
                                        
                                        {
                                            width: '100%',
                                            text: "Proficiência do Instrutor:",
                                            bold: true,
                                            margin: [0, 10, 0, 0],
                                            fontSize: 13,
                                            border: [false, false, false, false],
                                            alignment: 'center',
                                        },
                                    ],
                                    [
                                        {
                                            ul: [
                                                ...funcaoInstrutor
                                            ],
                                            margin: [20, 20, 20, 5],
                                            alignment: 'center',
                                            lineHeight: 1.2,
                                            type: 'none',
                                            border: [false, false, false, false],
                                        }
                                    ],
                                ],
                            },
                        },
                        // {
                        //     table: {
                        //         widths: ['100%'],
                        //         body: [
                        //             [
                                        
                        //                 {
                        //                     width: 130,
                        //                     image: `${treinamento.dados_responsavel_tecnico.responsavel_tecnico.assinatura}`,
                        //                     border: [false, false, false, false],
                        //                     alignment: 'center',
                        //                     margin: [0, 10, 0, 0],
                        //                     // absolutePosition: {x: 535, y:270},
                        //                 },
                        //             ],
                        //             // [
                        //             //     {
                        //             //         canvas: [
                        //             //             { type: 'line', x1: 5, y1: 5, x2: 250, y2: 5, lineWidth: 1 }, //Up line
                        //             //         ],
                        //             //         absolutePosition: {x: 485, y:315},
                        //             //         // alignment: 'center',
                        //             //         margin: [0, 0, 0, 0],
                        //             //         border: [false, false, false, false]
                        //             //     }
                        //             // ],
                        //             [
                        //                 {
                        //                     text: `${treinamento.dados_responsavel_tecnico.nome}`,
                        //                     alignment: 'center',
                        //                     lineHeight: .9,
                        //                     margin: [0, 0, 0, 0],
                        //                     bold: true,
                        //                     border: [false, false, false, false]
                        //                 },
                        //             ],
                        //             [
                        //                 {
                        //                     text: "Responsável Técnico - Engenheiro de Segurança 166427D",
                        //                     alignment: 'center',
                        //                     border: [false, false, false, false],
                        //                     margin: [0, 0, 0, 10],
                        //                 },
                        //             ],
                        //         ],
                        //     },
                        // },
                        {
                            table: {
                                widths: ['100%'],
                                body: [
                                    [
                                        
                                        {
                                            width: 130,
                                            image: `${treinamento.dados_instrutor.instrutor.assinatura}`,
                                            border: [false, false, false, false],
                                            alignment: 'center',
                                            margin: [0, 10, 0, 0],
                                            // absolutePosition: {x: 535, y:270},
                                        },
                                    ],
                                    // [
                                    //     {
                                    //         canvas: [
                                    //             { type: 'line', x1: 5, y1: 5, x2: 250, y2: 5, lineWidth: 1 }, //Up line
                                    //         ],
                                    //         absolutePosition: {x: 485, y:315},
                                    //         // alignment: 'center',
                                    //         margin: [0, 0, 0, 0],
                                    //         border: [false, false, false, false]
                                    //     }
                                    // ],
                                    [
                                        {
                                            text: `${treinamento.dados_instrutor.nome}`,
                                            alignment: 'center',
                                            lineHeight: .9,
                                            margin: [0, 0, 0, 0],
                                            bold: true,
                                            border: [false, false, false, false]
                                        },
                                    ],
                                    [
                                        {
                                            text: "Instrutor e Responsável Técnico",
                                            alignment: 'center',
                                            border: [false, false, false, false],
                                            margin: [0, 0, 0, 10],
                                        },
                                    ],
                                ],
                            },
                        }
                    ],
                ],
            },
            margin: [0, 10, 0, 0]
        }
    ];

    const pdfDefinitions = {
        pageSize: 'A4',
        pageOrientation: 'landscape',
        pageMargins: [44, 0, 44, 0],
        
        content: [details],
        // watermark: { text: "CTCPP", angle: 0, fontSize: 80},
        info: {
            title: `${aluno.nome}.pdf`
        },
    }

    pdfMake.createPdf(pdfDefinitions).open();
}