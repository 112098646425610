import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import Moment from 'react-moment';
import 'moment-timezone';
import "../../../styles/empresas.scss";
import "../../../styles/reset.scss";
import Modal from "../../layouts/modal";
import EmpresasService from '../../../services/empresas';
import ModalDangerSmall from '../../layouts/modalDangerSmall';
import jsonp from 'jsonp';

export default function ContentAdministracaoEmpresas() {
    const navigate = useNavigate();
    const [empresas, setEmpresas] = useState([]);
    const [cnpj, setCnpj] = useState([]);
    const [modalNovaEmpresa, setModalNovaEmpresa] = useState(false);
    const [modalEmpresa, setModalEmpresa] = useState(false);
    const [modalEditarEmpresa, setModalEditarEmpresa] = useState(false);
    const [modalDeleteEmpresa, setModalDeleteEmpresa] = useState(false);
    const [empresaAtiva, setEmpresaAtiva] = useState([]);
    const [finds, setFinds] = useState("");

    async function getCnpj(cnpj) {
        jsonp(`https://receitaws.com.br/v1/cnpj/${cnpj}`, null, function (err, data) {
            if (err) {
                console.error(err.message);
            } else {
                setCnpj(data);
            }
        });
    };

    async function getEmpresas() {
        const data = await EmpresasService.index();
        setEmpresas(data.data); 
    };

    async function createEmpresa(event) {
        event.preventDefault();

        const formData = new FormData(event.target);
        let form = Object.fromEntries(formData);

        try {
            await EmpresasService.create(form);
            await getEmpresas();
            setModalNovaEmpresa(false);
            setCnpj("");
        } catch (error) {
            console.log(error)
        }
    };

    async function editEmpresa(event) {
        event.preventDefault();

        const formData = new FormData(event.target);
        let form = Object.fromEntries(formData);

        try {
            await EmpresasService.edit(empresaAtiva._id, form);
            await getEmpresas();
            setModalEditarEmpresa(false);
        } catch (error) {
            console.log(error);
        }
    };

    async function desativarEmpresa(id) {
        try {
            await EmpresasService.desabilitar(id);
            getEmpresas();
            setModalDeleteEmpresa(false);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getEmpresas();
    }, []);

    // FILTRO
    function busca(a) {
        if (finds === "") {
            return a
        } else {
            return a.razao_social.toLowerCase().includes(finds.toLowerCase()) || a.fantasia.toLowerCase().includes(finds.toLowerCase()) || a.cnpj.toLowerCase().includes(finds.toLowerCase()) || a.cidade.toLowerCase().includes(finds.toLowerCase())
        }
    }
    
    const empresasAtivas = empresas?.filter(({ excluido }) => excluido === false);
    
    document.title = `Empresas - Centro de Treinamento`;

    return(
        <Fragment>
            <main id='main-empresas'>
                <div className="conteudo-empresas">
                    <div className="cabecalho-conteudo">
                        <h2>Empresas</h2>
                        <div className="buttons-conteudo">
                            <button className="button-red" onClick={()=> navigate(`/administracao`)}>Voltar</button>
                            <button className="button-blue">Imprimir</button>
                            <button className="button-green add-sinistro" onClick={()=> setModalNovaEmpresa(true)}>Nova empresa</button>
                        </div>
                    </div>
                    <div className='container-search'>
                        <input type='text' placeholder='Buscar' onChange={e => {setFinds(e.target.value)}}/>
                        <FontAwesomeIcon icon={faSearch}/>
                    </div>
                    <div className="tabela-dados">
                        <div className="cabecalho-dados">
                            <div className="column-15">CNPJ</div>
                            <div className="column-35">Nome Fantasia</div>
                            <div className="column-40">Razão Social</div>
                            <div className="column-10">Cadastro</div>
                        </div>
                        <div className="corpo-dados corpo-empresas">
                            { empresasAtivas.length === 0 ? 
                                <div className="row-dados">
                                    <div className="column-zerado">
                                        Não foi encontrado nenhum resultado.
                                    </div>
                                </div>
                                : (
                                    (empresasAtivas.filter(({excluido}) => excluido === false).slice(0).reverse().filter(busca).map((empresas, index) => (
                                        <Fragment>
                                            <div key={index} className="row-dados" onClick={() => {setEmpresaAtiva(empresas); setModalEmpresa(true)}}>
                                                <div className="column-15">
                                                    {empresas.cnpj}
                                                </div>
                                                <div className="column-35">
                                                    {empresas.fantasia}
                                                </div>
                                                <div className="column-40">
                                                    {empresas.razao_social}
                                                </div>
                                                <div className="column-10">
                                                    <Moment format="DD/MM/YYYY">
                                                        {empresas.created_at}
                                                    </Moment>
                                                </div>
                                            </div>
                                        </Fragment>
                                    )))
                                )
                            }
                            <h5>Exibindo {empresasAtivas.filter(({excluido}) => excluido === false).filter(busca).length} {empresasAtivas.filter(({excluido}) => excluido === false).filter(busca).length === 1 ? "resultado" : "resultados"}</h5>
                        </div>
                    </div>
                </div>
            </main>
            {modalNovaEmpresa ? (
                <Modal titulo="Nova Empresa" width="60%" onClose={() => {setModalNovaEmpresa(false); setCnpj("")}}>
                    <form onSubmit={createEmpresa}>
                        <div id="campos-nova-seguradora">
                            <div className="campo-form campo-25">
                                <label htmlFor="cnpj">CNPJ</label>
                                <input onBlur={(event) =>  (event.target.value.length !== 0 ? getCnpj(event.target.value) : setCnpj(""))} type="number" name="cnpj" id="cnpj"/>
                            </div>
                            <div className="campo-form campo-75">
                                <label htmlFor="razao_social">Razão Social</label>
                                <input type="text" name="razao_social" id="razao_social" defaultValue={cnpj["nome"]} autoComplete="off"/>
                            </div>
                            <div className="campo-form campo-60">
                                <label htmlFor="fantasia">Fantasia</label>
                                <input type="text" name="fantasia" id="fantasia" defaultValue={cnpj["fantasia"] === "" ? cnpj["nome"] : cnpj["fantasia"]}/>
                            </div>
                            <div className="campo-form campo-20">
                                <label htmlFor="telefone">Telefone</label>
                                <input type="text" name="telefone" id="telefone"/>
                            </div>
                            <div className="campo-form campo-20">
                                <label htmlFor="email">Email</label>
                                <input type="text" name="email" id="email" defaultValue={cnpj["email"]}/>
                            </div>
                            <div className="campo-form campo-30">
                                <label htmlFor="cnae">CNAE</label>
                                <input type="text" name="cnae" id="cnae" defaultValue={cnpj.length === 0 ? null : `${cnpj?.atividade_principal[0].code}`}/>
                            </div>
                            <div className="campo-form campo-70">
                                <label htmlFor="descricao_cnae">Descrição CNAE</label>
                                <input type="text" name="descricao_cnae" id="descricao_cnae" defaultValue={cnpj.length === 0 ? null : `${cnpj?.atividade_principal[0].text}`}/>
                            </div>
                            <div className="campo-form campo-60">
                                <label htmlFor="representante_financeiro">Responsável Financeiro</label>
                                <input type="text" name="representante_financeiro" id="representante_financeiro"/>
                            </div>
                            <div className="campo-form campo-20">
                                <label htmlFor="representante_financeiro_telefone">Telefone</label>
                                <input type="text" name="representante_financeiro_telefone" id="representante_financeiro_telefone"/>
                            </div>
                            <div className="campo-form campo-20">
                                <label htmlFor="representante_financeiro_email">Email</label>
                                <input type="text" name="representante_financeiro_email" id="representante_financeiro_email"/>
                            </div>
                            <div className="campo-form campo-20">
                                <label htmlFor="cep">CEP</label>
                                <input type="text" name="cep" id="cep" defaultValue={cnpj["cep"]}/>
                            </div>
                            <div className="campo-form campo-60">
                                <label htmlFor="logradouro">Logradouro</label>
                                <input type="text" name="logradouro" id="logradouro" defaultValue={cnpj.length === 0 ? null : `${cnpj["logradouro"]}`}/>
                            </div>
                            <div className="campo-form campo-20">
                                <label htmlFor="numero">Número</label>
                                <input type="text" name="numero" id="numero" defaultValue={cnpj["numero"]}/>
                            </div>
                            <div className="campo-form campo-20">
                                <label htmlFor="complemento">Complemento</label>
                                <input type="text" name="complemento" id="complemento" defaultValue={cnpj["complemento"]}/>
                            </div>
                            <div className="campo-form campo-30">
                                <label htmlFor="bairro">Bairro</label>
                                <input type="text" name="bairro" id="bairro" defaultValue={cnpj["bairro"]}/>
                            </div>
                            <div className="campo-form campo-30">
                                <label htmlFor="cidade">Cidade</label>
                                <input type="text" name="cidade" id="cidade" defaultValue={cnpj["municipio"]}/>
                            </div>
                            <div className="campo-form campo-20">
                                <label htmlFor="estado">Estado</label>
                                <select name="estado" id="estado" defaultValue={cnpj["uf"]}>
                                    <option value="default">Selecione</option>
                                    <option value="AC">Acre</option>
                                    <option value="AL">Alagoas</option>
                                    <option value="AM">Amazonas</option>
                                    <option value="AP">Amapá</option>
                                    <option value="BA">Bahia</option>
                                    <option value="CE">Ceará</option>
                                    <option value="DF">Distrito Federal</option>
                                    <option value="ES">Espirito Santo</option>
                                    <option value="GO">Goiás</option>
                                    <option value="MA">Maranhão</option>
                                    <option value="MT">Mato Grosso</option>
                                    <option value="MS">Mato Grosso do Sul</option>
                                    <option value="MG">Minas Gerais</option>
                                    <option value="PA">Pará</option>
                                    <option value="PB">Paraíba</option>
                                    <option value="PR">Paraná</option>
                                    <option value="PE">Pernambuco</option>
                                    <option value="PI">Piauí</option>
                                    <option value="RJ">Rio de Janeiro</option>
                                    <option value="RN">Rio Grande do Norte</option>
                                    <option value="RS">Rio Grande do Sul</option>
                                    <option value="SC">Santa Catarina</option>
                                    <option value="SP">São Paulo</option>
                                    <option value="SE">Sergipe</option>
                                    <option value="RO">Rondônia</option>
                                    <option value="RR">Roraima</option>
                                    <option value="TO">Tocantins</option>
                                </select>
                            </div>
                        </div>
                        <div className='buttons-form'>
                            <button type="reset" className="button-red" onClick={() => {setModalNovaEmpresa(false); setCnpj("")}}>Cancelar</button>
                            <button type="submit" className="button-green">Cadastrar</button>
                        </div>
                    </form>
                </Modal>
            ): null}
            {modalEmpresa ? (
                <Modal titulo="Informações Empresa" width={'60%'} onClose={() => {setModalEmpresa(false)}}>
                    <div className="info-modal">
                        <div className="campo-info campo-25">
                            <h5>CNPJ</h5>
                            <h4>{empresaAtiva.cnpj}</h4>
                        </div>
                        <div className="campo-info campo-75">
                            <h5>Razão Social</h5>
                            <h4>{empresaAtiva.razao_social}</h4>
                        </div>
                        <div className="campo-info campo-50">
                            <h5>Nome Fantasia</h5>
                            <h4>{empresaAtiva.fantasia}</h4>
                        </div>
                        <div className="campo-info campo-20">
                            <h5>Telefone</h5>
                            <h4>{empresaAtiva.telefone === "" ? "N/D" : empresaAtiva.telefone }</h4>
                        </div>
                        <div className="campo-info campo-30">
                            <h5>E-mail</h5>
                            <h4>{empresaAtiva.email === "" ? "N/D" : empresaAtiva.email}</h4>
                        </div>
                        <div className="campo-info campo-30">
                            <h5>CNAE</h5>
                            <h4>{empresaAtiva.cep}</h4>
                        </div>
                        <div className="campo-info campo-70">
                            <h5>Descrição CNAE</h5>
                            <h4>{empresaAtiva.descricao_cnae}</h4>
                        </div>
                        {(() => { 
                            if(empresaAtiva.responsavel_financeiro !== undefined) { 
                                return(<Fragment><div className="campo-info campo-50">
                                    <h5>Responsável Financeiro</h5>
                                    <h4>{empresaAtiva.responsavel_financeiro}</h4>
                                </div>
                                <div className="campo-info campo-20">
                                    <h5>Telefone</h5>
                                    <h4>{empresaAtiva.responsavel_financeiro_telefone}</h4>
                                </div>
                                <div className="campo-info campo-30">
                                    <h5>Email</h5>
                                    <h4>{empresaAtiva.responsavel_financeiro_email}</h4>
                                </div></Fragment>)
                            } 
                        })()}
                        <div className="campo-info campo-20">
                            <h5>CEP</h5>
                            <h4>{empresaAtiva.cep}</h4>
                        </div>
                        <div className="campo-info campo-60">
                            <h5>Logradouro</h5>
                            <h4>{empresaAtiva.logradouro}</h4>
                        </div>
                        <div className="campo-info campo-20">
                            <h5>Número</h5>
                            <h4>{empresaAtiva.numero}</h4>
                        </div>
                        <div className="campo-info campo-20">
                            <h5>Complemento</h5>
                            <h4>{empresaAtiva.complemento === "" ? "N/D" : empresaAtiva.complemento}</h4>
                        </div>
                        <div className="campo-info campo-30">
                            <h5>Bairro</h5>
                            <h4>{empresaAtiva.bairro}</h4>
                        </div>
                        <div className="campo-info campo-30">
                            <h5>Cidade</h5>
                            <h4>{empresaAtiva.cidade}</h4>
                        </div>
                        <div className="campo-info campo-20">
                            <h5>Estado</h5>
                            <h4>{empresaAtiva.estado}</h4>
                        </div>
                    </div>
                    <div className='buttons-info'>
                        <button type="reset" className="button-red" onClick={() => {setModalEmpresa(false)}}>Cancelar</button>
                        <button type="button" className="button-darkred" onClick={() => {setModalEmpresa(false); setModalDeleteEmpresa(true)}}>Excluir</button>
                        <button type="button" className="button-cyan" onClick={() => {setModalEmpresa(false); setModalEditarEmpresa(true)}}>Editar</button>
                    </div>
                </Modal>
            ): null}
            {modalEditarEmpresa ? (
                <Modal titulo="Editar Empresa" width="60%" onClose={() => {setModalEditarEmpresa(false); setCnpj("")}}>
                    <form onSubmit={editEmpresa}>
                        <div id="campos-nova-seguradora">
                            <div className="campo-form campo-25">
                                <label htmlFor="cnpj">CNPJ</label>
                                <input type="number" name="cnpj" id="cnpj" defaultValue={empresaAtiva.cnpj}/>
                            </div>
                            <div className="campo-form campo-75">
                                <label htmlFor="razao_social">Razão Social</label>
                                <input type="text" name="razao_social" id="razao_social" defaultValue={empresaAtiva.razao_social} autoComplete="off"/>
                            </div>
                            <div className="campo-form campo-60">
                                <label htmlFor="fantasia">Fantasia</label>
                                <input type="text" name="fantasia" id="fantasia" defaultValue={empresaAtiva.fantasia}/>
                            </div>
                            <div className="campo-form campo-20">
                                <label htmlFor="telefone">Telefone</label>
                                <input type="text" name="telefone" id="telefone" defaultValue={empresaAtiva.telefone}/>
                            </div>
                            <div className="campo-form campo-20">
                                <label htmlFor="email">Email</label>
                                <input type="text" name="email" id="email" defaultValue={empresaAtiva.email}/>
                            </div>
                            <div className="campo-form campo-30">
                                <label htmlFor="cnae">CNAE</label>
                                <input type="text" name="cnae" id="cnae" defaultValue={empresaAtiva.cnae}/>
                            </div>
                            <div className="campo-form campo-70">
                                <label htmlFor="descricao_cnae">Descrição CNAE</label>
                                <input type="text" name="descricao_cnae" id="descricao_cnae" defaultValue={empresaAtiva.descricao_cnae}/>
                            </div>
                            <div className="campo-form campo-60">
                                <label htmlFor="representante_financeiro">Responsável Financeiro</label>
                                <input type="text" name="representante_financeiro" id="representante_financeiro" defaultValue={empresaAtiva.responsavel_financeiro}/>
                            </div>
                            <div className="campo-form campo-20">
                                <label htmlFor="representante_financeiro_telefone">Telefone</label>
                                <input type="text" name="representante_financeiro_telefone" id="representante_financeiro_telefone" defaultValue={empresaAtiva.responsavel_financeiro_telefone}/>
                            </div>
                            <div className="campo-form campo-20">
                                <label htmlFor="representante_financeiro_email">Email</label>
                                <input type="text" name="representante_financeiro_email" id="representante_financeiro_email" defaultValue={empresaAtiva.responsavel_financeiro_email}/>
                            </div>
                            <div className="campo-form campo-20">
                                <label htmlFor="cep">CEP</label>
                                <input type="text" name="cep" id="cep" defaultValue={empresaAtiva.cep}/>
                            </div>
                            <div className="campo-form campo-60">
                                <label htmlFor="logradouro">Logradouro</label>
                                <input type="text" name="logradouro" id="logradouro" defaultValue={empresaAtiva.logradouro}/>
                            </div>
                            <div className="campo-form campo-20">
                                <label htmlFor="numero">Número</label>
                                <input type="text" name="numero" id="numero" defaultValue={empresaAtiva.numero}/>
                            </div>
                            <div className="campo-form campo-20">
                                <label htmlFor="complemento">Complemento</label>
                                <input type="text" name="complemento" id="complemento" defaultValue={empresaAtiva.complemento}/>
                            </div>
                            <div className="campo-form campo-30">
                                <label htmlFor="bairro">Bairro</label>
                                <input type="text" name="bairro" id="bairro" defaultValue={empresaAtiva.bairro}/>
                            </div>
                            <div className="campo-form campo-30">
                                <label htmlFor="cidade">Cidade</label>
                                <input type="text" name="cidade" id="cidade" defaultValue={empresaAtiva.cidade}/>
                            </div>
                            <div className="campo-form campo-20">
                                <label htmlFor="estado">Estado</label>
                                <select name="estado" id="estado" defaultValue={empresaAtiva.estado}>
                                    <option value="default">Selecione</option>
                                    <option value="AC">Acre</option>
                                    <option value="AL">Alagoas</option>
                                    <option value="AM">Amazonas</option>
                                    <option value="AP">Amapá</option>
                                    <option value="BA">Bahia</option>
                                    <option value="CE">Ceará</option>
                                    <option value="DF">Distrito Federal</option>
                                    <option value="ES">Espirito Santo</option>
                                    <option value="GO">Goiás</option>
                                    <option value="MA">Maranhão</option>
                                    <option value="MT">Mato Grosso</option>
                                    <option value="MS">Mato Grosso do Sul</option>
                                    <option value="MG">Minas Gerais</option>
                                    <option value="PA">Pará</option>
                                    <option value="PB">Paraíba</option>
                                    <option value="PR">PR</option>
                                    <option value="PE">Pernambuco</option>
                                    <option value="PI">Piauí</option>
                                    <option value="RJ">Rio de Janeiro</option>
                                    <option value="RN">Rio Grande do Norte</option>
                                    <option value="RS">Rio Grande do Sul</option>
                                    <option value="SC">Santa Catarina</option>
                                    <option value="SP">São Paulo</option>
                                    <option value="SE">Sergipe</option>
                                    <option value="RO">Rondônia</option>
                                    <option value="RR">Roraima</option>
                                    <option value="TO">Tocantins</option>
                                </select>
                            </div>
                        </div>
                        <div className='buttons-form'>
                            <button type="reset" className="button-red" onClick={() => {setModalEditarEmpresa(false); setModalEmpresa(true)}}>Cancelar</button>
                            <button type="submit" className="button-green">Salvar</button>
                        </div>
                    </form>
                </Modal>
            ): null}
            {modalDeleteEmpresa ? (
                <ModalDangerSmall mensagem={['Deseja excluir a empresa', <br/>,<span>{empresaAtiva.fantasia}</span>,"?"]} onClose={() => {setModalDeleteEmpresa(false)}}>
                    <Fragment>
                        <div className='buttons-form'>
                            <button type="button" className="button-red" onClick={() => {setModalDeleteEmpresa(false); setModalEmpresa(true)}}>Cancelar</button>
                            <button type="submit" className="button-blue" onClick={() => {desativarEmpresa(empresaAtiva._id)}}>Excluir</button>
                        </div>
                    </Fragment>
                </ModalDangerSmall>
            ): null}
        </Fragment>
    )
};