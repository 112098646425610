import Api from './api';

const CursosService = {
    index: () => Api.get('/cursos'),
    curso: (id) => Api.get(`/cursos/${id}`),
    create: async (params) => await Api.post(`/cursos`, (params)),
    editar: async (id, params) => await Api.put(`/cursos/${id}`, (params)),
    desativar_curso: async (id) => await Api.put(`/cursos/${id}/delete`),
    restaurar_curso: async (id) => await Api.put(`/cursos/${id}/restore`),
    delete_curso: async (id) => await Api.delete(`/cursos/${id}`),
    // criar_historico: async (id, params) => await Api.post(`/cursos/${id}/historicos`, (params)),
    // historicos: (id) => Api.get(`/cursos/${id}/historicos`),
    // criar_agenda: async (id, params) => await Api.post(`/cursos/${id}/agendas`, (params)),
    // agendas: (id) => Api.get(`/cursos/${id}/agendas`),
    // edit_beneficiario: async (id, otherId, params) => await Api.put(`/cursos/${id}/beneficiarios/${otherId}`, (params)),
    // delete_beneficiario: async (id, otherId) => await Api.delete(`/cursos/${id}/beneficiarios/${otherId}`)
}

export default CursosService;