import React, { Fragment, useEffect, useState } from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faLightbulb, faBug, faWrench, faMicrochip } from '@fortawesome/free-solid-svg-icons'
import Moment from 'react-moment';
import 'moment-timezone';
import 'moment/locale/pt-br'
import "../../styles/configuracoes.scss";
import "../../styles/reset.scss";
import UsersService from '../../services/users';
import TreinamentosService from '../../services/treinamentos';
import ChamadosService from '../../services/chamados';
import CursosService from '../../services/cursos';
import CertificadosService from '../../services/certificados';
import Loading from '../layouts/loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faBookmark, faChartColumn, faPen, faUserGear } from '@fortawesome/free-solid-svg-icons';
import AlunosService from '../../services/alunos';
Moment.globalLocale = 'pt-BR'

export default function ContentConfiguracoes() {
    const [userLogado, setUserLogado] = useState();
    const [sobrenome, setSobrenome] = useState();
    const [nome, setNome] = useState();
    const [funcoes, setFuncoes] = useState();
    const [carregado, setCarregado] = useState(false);
    const [opcaoAtiva, setOpcaoAtiva] = useState("perfil");
    const [chamados, setChamados] = useState();
    const [treinamentos, setTreinamentos] = useState();
    const [cursos, setCursos] = useState();
    const [certificados, setCertificados] = useState();
    const [alunosTreinados, setAlunosTreinados] = useState();

    async function getUserLogado() {
        const res = await UsersService.logado();
        setUserLogado(res.data);
        setCarregado(true);
        setFuncoes(res.data.user.funcoes);
        
        const nome_e_sobrenome = res.data.user.name;
        const espacoInicial = nome_e_sobrenome.indexOf(' ');

        if (espacoInicial !== -1) {
            const sobren = nome_e_sobrenome.substring(espacoInicial+1);
            const nom = nome_e_sobrenome.substring(0, espacoInicial);
            setSobrenome(sobren);
            setNome(nom);
        } else {
            setNome(nome_e_sobrenome)
        }
    }

    async function getRelatorios() {
        const response_chamados = await ChamadosService.index();
        setChamados(response_chamados.data);
        const response_cursos = await CursosService.index();
        setCursos(response_cursos.data);
        const response_treinamentos = await TreinamentosService.index();
        setTreinamentos(response_treinamentos.data);
        const response_alunos = await AlunosService.index();
        setAlunosTreinados(response_alunos.data);
        const response_certificados = await CertificadosService.index();
        setCertificados(response_certificados.data);
    }

    useEffect(() => {
        getRelatorios();
        getUserLogado();
        // eslint-disable-next-line
    }, []);

    async function alterarFoto(e) {
        e.preventDefault();

        // const formData = new FormData(e.target);
        // const data = Object.fromEntries(formData);
        const data = {
            "foto": e.target.files[0]
        };
        
        await UsersService.alterar_foto(data);
        window.location.reload();
    };

    async function alterarNotificacoes(e) {
        e.preventDefault();
        
        const data = {
            not_loginIncorreto: document.getElementById("not_loginIncorreto").checked,
            not_respostaChamado: document.getElementById("not_respostaChamado").checked,
            not_citarChamado: document.getElementById("not_citarChamado").checked,
            not_mencaoChamado: document.getElementById("not_mencaoChamado").checked,
            not_addInstrutor: document.getElementById("not_addInstrutor").checked,
            not_finTreinInstrutor: document.getElementById("not_finTreinInstrutor").checked,
            not_addAlunoTreinInstrutor: document.getElementById("not_addAlunoTreinInstrutor").checked
        }

        try {
            await UsersService.editar_dados({configuracoes: data});
            window.location.reload()
        } catch (error) {
            console.log(error);
        }
    }

    // async function getAlunosFormados(alunosG) {
    //     var countAlunosFormados = [];

    //     alunosG.forEach(element => {
    //         element.treinamentos.forEach(t => {
    //             if (t.concluido === true) {
    //                 countAlunosFormados.push(t);
    //                 setAlunosFormados(countAlunosFormados);
    //             }
    //         });
    //     });
    // };

    // async function getTempoTreinamentos(treinamentosG) {
    //     var countTempoTotal = 0;

    //     // eslint-disable-next-line
    //     await treinamentosG.filter(({ status }) => status[status.length - 1].situacao === "Finalizado").map((item) => {
    //             countTempoTotal += item.dados_curso.carga_horaria;
    //             setTempoTreinamentos(countTempoTotal);
    //             // console.log(item.curso.carga_horaria)
    //     });
    // };

    async function alterarDados(e) {
        e.preventDefault();

        const formData = new FormData(e.target);
        const data = Object.fromEntries(formData);

        switch (true) {
            case data.email === "":
                delete data.email;
            // eslint-disable-next-line
            case data.name === "":
                delete data.name;
            // eslint-disable-next-line
            case data.sobrenome === "":
                delete data.sobrenome;
            // eslint-disable-next-line
            default:
            break;
        }

        data.funcoes = data.funcoes.replace(/\s*;\s*|\s*;\s*/g, ";").split(';');

        data.name = `${data.name} ${data.sobrenome}`;
        delete data.sobrenome;

        if (data.password === "") {
            delete data.password;
        } else {
            UsersService.alterar_senha({password: data.password});
        }

        await UsersService.editar_dados(data);
        window.location.reload();
    }

    document.title = `Configurações - Centro de Treinamento`;
    
    return(
        <Fragment>
            { carregado === true ?
                <main id='main-configuracoes'>
                <div className='conteudo-configuracoes'>
                    <div className='menu-configuracoes'>
                        <div className='itens-menu-configuracoes'>
                            <h3>Configurações</h3>
                            <ul>
                                <li onClick={() => {setOpcaoAtiva("perfil")}} className={opcaoAtiva === "perfil" ? "active" : null}><FontAwesomeIcon icon={faUserGear}/><p>Perfil</p></li>
                                { userLogado.user.permission === 1 ? <li onClick={() => {setOpcaoAtiva("atalhos")}} className={opcaoAtiva === "atalhos" ? "active" : null}><FontAwesomeIcon icon={faBookmark}/><p>Atalhos</p></li> : null}
                                <li onClick={() => {setOpcaoAtiva("notificações")}} className={opcaoAtiva === "notificações" ? "active" : null}><FontAwesomeIcon icon={faBell}/><p>Notificações</p></li>
                                <li onClick={() => {setOpcaoAtiva("dashboard")}} className={opcaoAtiva === "dashboard" ? "active" : null}><FontAwesomeIcon icon={faChartColumn}/><p>Dashboard</p></li>
                            </ul>
                        </div>
                    </div>
                    <div className='informacoes-configuracoes'>
                        {(() => {
                            switch (true) {
                                case opcaoAtiva === 'perfil':
                                    return(
                                        <div className='aba-configuracao-perfil'>
                                            <div className='info-user'>
                                                <div className='imagem-usuario'>
                                                    <form onSubmit={alterarFoto} id="form-foto">
                                                        <input id="imagem" type="file" name="imagem" onChange={alterarFoto} accept=".jpg, .jpeg, .png"/>
                                                        <label htmlFor="imagem" className="custom-file-upload">
                                                            <FontAwesomeIcon icon={faPen}/>
                                                        </label>
                                                    </form>
                                                    <img src={userLogado.url_images + userLogado.user.imagem} alt={`Imagem do usuário ${userLogado.name}`} />
                                                </div>
                                                <div className='titulo-usuario'>
                                                {(() => {
                                                    switch (true) {
                                                        case (userLogado.user.permission === 1):
                                                            return(<Fragment><h3 style={{color: "#007bff"}}>{userLogado.user.name}</h3><p>Desenvolvedor</p></Fragment>)
                                                        case (userLogado.user.permission === 2):
                                                            return(<Fragment><h3 style={{color: "#000080"}}>{userLogado.user.name}</h3><p>Diretor</p></Fragment>)
                                                        case (userLogado.user.permission === 3):
                                                            return(<Fragment><h3 style={{color: "#FF0000"}}>{userLogado.user.name}</h3><p>Financeiro</p></Fragment>)
                                                        case (userLogado.user.permission === 4):
                                                            return(<Fragment><h3 style={{color: "#006400"}}>{userLogado.user.name}</h3><p>Instrutor</p></Fragment>)
                                                        default:
                                                        break;
                                                    }
                                                })()}
                                                </div>
                                            </div>
                                            <form onSubmit={alterarDados} className='formulario-usuario'>
                                                <div className='campo-form campo-50'>
                                                    <label htmlFor='name'>Nome</label>
                                                    <input id='name' name='name' defaultValue={nome}/>
                                                </div>
                                                <div className='campo-form campo-50'>
                                                    <label htmlFor='sobrenome'>Sobrenome</label>
                                                    <input id='sobrenome' name='sobrenome' defaultValue={sobrenome}/>
                                                </div>
                                                <div className='campo-form campo-50'>
                                                    <label htmlFor='email'>E-mail</label>
                                                    <input id='email' name='email' defaultValue={userLogado.user.email}/>
                                                </div>
                                                <div className='campo-form campo-50'>
                                                    <label htmlFor='password'>Senha</label>
                                                    <input type='password' id='password' name='password'/>
                                                </div>
                                                <div className='campo-form campo-100'>
                                                    <label htmlFor='funcoes'>Funções<span>Faça a separação por ponto e virgula (;)</span></label>
                                                    <textarea rows={3} id='funcoes' name='funcoes' defaultValue={funcoes}/>
                                                </div>
                                                <button className='button-green'>Salvar</button>
                                            </form>
                                        </div>
                                    )
                                case opcaoAtiva === 'atalhos':
                                    return(
                                        <div className='aba-configuracoes-atalhos'>
                                            <h3>Atalhos</h3>
                                            <p>Selecione os icones que de atalho que deseja fixar na página inicial</p>
                                            <div className='container-configuracoes-atalhos'>
                                                <div className='cabecalho-container-atalhos'>
                                                    <button className='button-green'>Adicionar</button>
                                                </div>
                                                { userLogado.user.configuracoes.atalhos.length === 0 ?
                                                    <div className='column-zerado'>
                                                        <p>Não foi encontrado nenhum atalho.<br/>Clique no botão acima para adicionar atalhos.</p>
                                                    </div>
                                                :
                                                    <div className='lista-atalhos'>
                                                        {/* {userLogado.user.configuracoes.atalhos.map((item) => (
                                                            <a className='atalho'>
                                                                {item.nome}
                                                            </a>
                                                        ))} */}
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    )
                                case opcaoAtiva === 'notificações':
                                    return(
                                        <form onSubmit={alterarNotificacoes} className='aba-configuracao-notificacoes'>
                                            <div className='categoria-configuração-notificacoes'>
                                                <h3>Notificações Gerais</h3>
                                                <div>
                                                    <input type="checkbox" name='not_loginIncorreto' id='not_loginIncorreto' defaultChecked={userLogado.user.configuracoes.not_loginIncorreto}/>
                                                    <label htmlFor='not_loginIncorreto'>Caso tenha uma tentativa de login com senha incorreta</label>
                                                </div>
                                            </div>
                                            <div className='categoria-configuração-notificacoes'>
                                                <h3>Notificações de Chamados</h3>
                                                <div>
                                                    <input type="checkbox" name='not_respostaChamado' id='not_respostaChamado' defaultChecked={userLogado.user.configuracoes.not_respostaChamado}/>
                                                    <label htmlFor='not_respostaChamado'>Ao ter uma nova resposta no chamado em que criei</label>
                                                </div>
                                                <div>
                                                    <input type="checkbox" name='not_citarChamado' id='not_citarChamado' defaultChecked={userLogado.user.configuracoes.not_citarChamado}/>
                                                    <label htmlFor='not_citarChamado'>Quando fizerem uma citação ao meu post</label>
                                                </div>
                                                <div>
                                                    <input type="checkbox" name='not_mencaoChamado' id='not_mencaoChamado' defaultChecked={userLogado.user.configuracoes.not_mencaoChamado}/>
                                                    <label htmlFor='not_mencaoChamado'>Me mencionarem em algum chamado</label>
                                                </div>
                                            </div>
                                            <div className='categoria-configuração-notificacoes'>
                                                <h3>Notificações de Treinamentos</h3>
                                                <div>
                                                    <input type="checkbox" name='not_addInstrutor' id='not_addInstrutor' defaultChecked={userLogado.user.configuracoes.not_addInstrutor}/>
                                                    <label htmlFor='not_addInstrutor'>Ao me adicionarem como instrutor em um treinamento</label>
                                                </div>
                                                <div>
                                                    <input type="checkbox" name='not_finTreinInstrutor' id='not_finTreinInstrutor' defaultChecked={userLogado.user.configuracoes.not_finTreinInstrutor}/>
                                                    <label htmlFor='not_finTreinInstrutor'>Ao marcarem o treinamento em que sou instrutor como concluído</label>
                                                </div>
                                                <div>
                                                    <input type="checkbox" name='not_addAlunoTreinInstrutor' id='not_addAlunoTreinInstrutor' defaultChecked={userLogado.user.configuracoes.not_addAlunoTreinInstrutor}/>
                                                    <label htmlFor='not_addAlunoTreinInstrutor'>Ao adicionarem um novo aluno no treinamento em que sou instrutor</label>
                                                </div>
                                            </div>
                                            <button type='submit' className='button-green'>Salvar</button>
                                        </form>
                                    )
                                case opcaoAtiva === 'dashboard':
                                    return(
                                        <div className='aba-configuracao-dashboard'>
                                            <div className='cards-dashboard'>
                                                <div className='item-cards'>
                                                    <h3>{chamados.filter(({created_by}) => created_by._id === userLogado.user._id).length}</h3>
                                                    <p>Chamados criados por mim</p>
                                                </div>
                                                <div className='item-cards'>
                                                    <h3>{treinamentos?.filter(({created_by}) => created_by === userLogado.user._id).length}</h3>
                                                    <p>Treinamentos criados por mim</p>
                                                </div>
                                                <div className='item-cards'>
                                                    <h3>{treinamentos?.filter(({instrutor}) => instrutor === userLogado.user._id).length}</h3>
                                                    <p>Treinamentos como instrutor</p>
                                                </div>
                                                <div className='item-cards'>
                                                    <h3>{cursos?.filter(({created_by}) => created_by === userLogado.user._id).length}</h3>
                                                    <p>Cursos criados por mim</p>
                                                </div>
                                                <div className='item-cards'>
                                                    <h3>0</h3>
                                                    <p>Anotações</p>
                                                </div>
                                            </div>
                                            <div className='container-dashboard container-chamados'>
                                                <h2>Seus chamados</h2>
                                                <div className='content-container-dashboard'>
                                                    { chamados.filter(({created_by}) => created_by._id === userLogado.user._id).length === 0 ?
                                                        <div className='column-zerado'>Não existe nenhum chamado criado por você.</div>
                                                    :
                                                        chamados.filter(({created_by}) => created_by._id === userLogado.user._id).map((item) => (
                                                            <div className='item-content-container'>
                                                                <a href={`/chamados/${item.area.toLowerCase().replace("õ", "o")}/${item._id}`}>
                                                                    <h3>{item.titulo}</h3>
                                                                    <p>{item.area}</p>
                                                                    <p>Criado em: <Moment format='DD/MM/YYYY'>{item.created_at}</Moment></p>
                                                                    <p>Situação: <span>{item.concluido === true ? "Finalizado" : "Em andamento" }</span></p>
                                                                </a>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                            <div className='container-dashboard container-treinamentos'>
                                                <h2>Treinamentos</h2>
                                                <div className='content-container-dashboard'>
                                                    { treinamentos.length === 0 ?
                                                        <div className='column-zerado'>Não existe nenhum treinamento cadastrado.</div>
                                                    :
                                                        treinamentos.map((item) => (
                                                            <div className='item-content-container'>
                                                                <a href={`/treinamento/${item._id}`}>
                                                                    <h3>{item.titulo}</h3>
                                                                    <p>{item.local.nome}</p>
                                                                    <p>Quantidade de Alunos: {item.alunos.length}</p>
                                                                </a>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                            <div className='cards-dashboard'>
                                                <div className='item-cards-big'>
                                                    <p>Certificados emitidos</p>
                                                    <h3>{certificados.length}</h3>
                                                </div>
                                                <div className='item-cards-big'>
                                                    <p>Cursos disponíveis</p>
                                                    <h3>{cursos.length}</h3>
                                                </div>
                                                <div className='item-cards-big'>
                                                    <p>Alunos treinados</p>
                                                    <h3>{alunosTreinados.length}</h3>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                default:
                                break;
                            }
                        })()}
                    </div>
                </div>
                </main>
            :
                <Loading/>
            }
        </Fragment>
    )
};