import { Fragment } from 'react';
import Header from '../../components/header/index';
import ContentTreinamento from '../../components/treinamentos/treinamento';

const TreinamentoScreen = (props) => (
  <Fragment>
    <Header ativo={props.ativo}/>
    <ContentTreinamento/>
  </Fragment>
)

export default TreinamentoScreen;