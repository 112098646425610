import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan, faUserPen } from '@fortawesome/free-solid-svg-icons';
import "../../../styles/usuarios.scss";
import "../../../styles/reset.scss";
import Modal from '../../layouts/modal';
import ModalDangerSmall from '../../layouts/modalDangerSmall';
import UsersService from '../../../services/users';
import Loading from '../../layouts/loading';

function ContentAdministracao() {
    const navigate = useNavigate();
    const [users, setUsers] = useState([]);
    // const [empresas, setEmpresas] = useState([]);
    const [userAtivo, setUserAtivo] = useState();
    const [userLogado, setUserLogado] = useState();
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalEditUser, setModalEditUser] = useState(false);
    const [modalDeleteUser, setModalDeleteUser] = useState(false);
    const [carregado, setCarregado] = useState(false);

    async function getUsers() {
        const response = await UsersService.index();
        setUsers(response.data);
    }

    async function getUserLogado() {
        const response_user = await UsersService.logado();
        setUserLogado(response_user.data.user)
    }

    useEffect(() => {
        (async function() {
            await getUserLogado();
            await getUsers();
            setCarregado(true);
        })();
    }, []);

    const createUsuario = async event => {
        event.preventDefault();
        const formData = new FormData(event.target);
        const data = Object.fromEntries(formData);

        data.email = data.email.toLocaleLowerCase();

        if (data.permission === "4") {
            data.instrutor = true;
        }

        data.funcoes = data.funcoes.split(", ");
        
        try {
            // console.log(data)
            await UsersService.registrar(data);
            setModalIsOpen(false);
            getUsers();
        } catch (error) {
            console.log(error)
        }
    }

    const editUsuario = async event => {
        event.preventDefault();
        const formData = new FormData(event.target);
        const data = Object.fromEntries(formData);
        
        try {
            await UsersService.editar_usuario(userAtivo._id, data);
            window.location.reload();
        } catch (error) {
            console.log(error)
        }
    }

    async function desativarUser() {
        await UsersService.desativar(userAtivo._id);
        window.location.reload();
    };

// ORDENAR PERMISSÃO
    function ordemPermissao(a, b){
        return a.permission < b.permission ? -1 : a.permission > b.permission ? 1 : 0;
    }

    document.title = 'Usuários - Centro de Treinamento';

    return(
        <Fragment>
            { carregado ?
            (<main id='main-usuarios'>
                <div className="cabecalho-conteudo">
                    <h2>Usuários</h2>
                    <div className="buttons-conteudo">
                        <button className="button-red" onClick={()=> navigate(`/administracao`)}>Voltar</button>
                        <button className="button-blue">Imprimir</button>
                        <button className="button-green" onClick={() => setModalIsOpen(true)}>Novo Usuário</button>
                    </div>
                </div>
                <div className="content-usuarios">
                    {users.user?.filter(({ excluido }) => excluido === false).sort(ordemPermissao).map((item, index) => (
                        <div key={index} className="list-itens">
                            <div className="content-item">
                                <div className='img-user'>
                                    <img src={users.url_images + item.imagem} alt={`Foto de ${item.name}`}/>
                                </div>
                                <div className="titulo-usuario">
                                    {(() => {
                                            switch (true) {
                                                case (item.permission === 1):
                                                    return(<Fragment><h3 style={{color: "#007bff"}}>{item.name}</h3><span>Desenvolvedor</span></Fragment>)
                                                case (item.permission === 2):
                                                    return(<Fragment><h3 style={{color: "#000080"}}>{item.name}</h3><span>Diretor</span></Fragment>)
                                                case (item.permission === 3):
                                                    return(<Fragment><h3 style={{color: "#FF0000"}}>{item.name}</h3><span>Financeiro</span></Fragment>)
                                                case (item.permission === 4):
                                                    return(<Fragment><h3 style={{color: "#006400"}}>{item.name}</h3><span>Instrutor</span></Fragment>)
                                                default:
                                                break;
                                            }
                                    })()}
                                </div>
                                <div className="campo-info-user">
                                    <h4>E-mail:</h4>
                                    <h5>
                                        {item.email}
                                    </h5>
                                </div>
                                <div className="campo-info-user">
                                    <h4>Funções:</h4>
                                    {/* {item.funcoes.map((func, index) => {
                                        <h5 key={index}>{func}</h5>
                                    })} */}
                                </div>
                            </div>
                            <button onClick={() => {setUserAtivo(item); setModalEditUser(true)}}>
                                <FontAwesomeIcon icon={faUserPen}/>
                                <p>Editar</p>
                            </button>
                            {/* {item.permission <= 2 ? 
                                (<a className='a-desativado'>)
                            : 
                                (<a onClick={() => {desativarUser(item._id)}}>)
                            } */}
                            {/* <a {item.permission <= 2 ? className='a-desativado' : onClick={desativarUser(item._id)}>
                                <FontAwesomeIcon icon={faTrashCan}/>
                                <p>Excluir</p>
                            </a> */}
                            {item.permission <= 2 && userLogado.permission >= 3 ?
                                (<button className='button-desativado'>
                                    <FontAwesomeIcon icon={faTrashCan}/>
                                    <p>Excluir</p>
                                </button>)
                            :
                                (<button onClick={() => {setUserAtivo(item); setModalDeleteUser(true);} }>
                                    <FontAwesomeIcon icon={faTrashCan}/>
                                    <p>Excluir</p>
                                </button>)
                            }
                        </div>
                    ))}
                </div>
            </main>)
            : 
            (
            <Loading>

            </Loading>
            )}
            {modalIsOpen ? (
                <Modal titulo="Novo Usuário" onClose={() => {setModalIsOpen(false)}}>
                    <form onSubmit={createUsuario}>
                        <div id="campos-novo-usuario">
                            <div className="campo-form campo-100">
                                <label htmlFor="email-usuario">E-mail</label>
                                <input type="email" name="email" id="email-usuario"/>
                            </div>
                            <div className="campo-form campo-60">
                                <label htmlFor="name-usuario">Nome</label>
                                <input type="text" name="name" id="name-usuario"/>
                            </div>
                            <div className="campo-form campo-40">
                                <label htmlFor="permissao-usuario">Permissão do Usuário</label>
                                <select name="permission" id="permissao-usuario">
                                    <option value="default">Selecione</option>
                                    <option value="1">Desenvolvedor</option>
                                    <option value="2">Diretor</option>
                                    <option value="3">Financeiro</option>
                                    <option value="4">Instrutor</option>
                                </select>
                            </div>
                            <div className="campo-form campo-100">
                                <label htmlFor="funcoes">Funções (Separar cada uma por virgulas)</label>
                                <textarea type="text" name="funcoes" id="funcoes" rows="2"/>
                            </div>
                        </div>
                        <div className='buttons-form'>
                            <button type="reset" className="button-red" onClick={() => {setModalIsOpen(false)}}>Cancelar</button>
                            <button type="submit" className="button-green">Cadastrar</button>
                        </div>
                    </form>
                </Modal>
            ): null}
            {modalDeleteUser ? (
                <ModalDangerSmall mensagem={['Deseja excluir o usuário', <br/>,<span>{userAtivo.name}</span>,"?"]} onClose={() => {setModalDeleteUser(false)}}>
                    <Fragment>
                        <div className='buttons-form'>
                            <button type="button" className="button-red" onClick={() => {setModalDeleteUser(false)}}>Cancelar</button>
                            <button type="submit" className="button-blue" onClick={() => {desativarUser()}}>Excluir</button>
                        </div>
                    </Fragment>
                </ModalDangerSmall>
            ): null}
            {modalEditUser ? (
                <Modal titulo="Editar Usuário" onClose={() => {setModalEditUser(false)}}>
                    <form onSubmit={editUsuario}>
                        <div id="campos-novo-usuario">
                            <div className="campo-form campo-50">
                                <label htmlFor="email-usuario">E-mail</label>
                                <input type="email" name="email" id="email-usuario" defaultValue={userAtivo.email}/>
                            </div>
                            {/* <div className="campo-form campo-50">
                                <label htmlFor="password-usuario">Senha</label>
                                <input type="password" name="password" id="password-usuario"/>
                            </div> */}
                            <div className="campo-form campo-50">
                                <label htmlFor="name-usuario">Nome</label>
                                <input type="text" name="name" id="name-usuario" defaultValue={userAtivo.name}/>
                            </div>
                            <div className="campo-form campo-50">
                                <label htmlFor="empresa-usuario">Empresa</label>
                                <select name="empresa" id="empresa-usuario">
                                    <option value="default">Selecione</option>
                                    {/* {empresas?.map((empresas) => (
                                        <option key={empresas._id} value={empresas._id}>{empresas.fantasia}</option>
                                    ))} */}
                                </select>
                            </div>
                            <div className="campo-form campo-50">
                                <label htmlFor="permissao-usuario">Permissão do Usuário</label>
                                <select name="permission" id="permissao-usuario" defaultValue={userAtivo.permission}>
                                    <option value="default">Selecione</option>
                                    <option value="1">Desenvolvedor</option>
                                    <option value="2">Diretor</option>
                                    <option value="3">Financeiro</option>
                                    <option value="4">Instrutor</option>
                                </select>
                            </div>
                        </div>
                        <div className='buttons-form'>
                            <button type="button" className="button-red" onClick={() => {setModalEditUser(false)}}>Cancelar</button>
                            <button type="submit" className="button-green">Salvar</button>
                        </div>
                    </form>
                </Modal>
            ): null}
        </Fragment>
    )
};
   
export default ContentAdministracao;