import Api from './api';

const LocaisService = {
    index: () => Api.get('/locais'),
    create: (params) => Api.post('/locais', params),
    edit: (id, params) => Api.put(`/locais/${id}`, params),
    desabilitar: (id) => Api.put(`/locais/${id}/desabilitar`),
    ativar: (id) => Api.put(`/locais/${id}/habilitar`),
    delete: (id) => Api.delete(`/locais/${id}`)
}

export default LocaisService;