import { Fragment } from 'react';
import Header from '../../components/header/index';
import ContentTreinamentos from '../../components/treinamentos';

const TreinamentosScreen = (props) => (
  <Fragment>
    <Header ativo={props.ativo}/>
    <ContentTreinamentos/>
  </Fragment>
)

export default TreinamentosScreen;