import Api from './api';

const CertificadosService = {
    index: () => Api.get('/certificados'),
    certificado: (id) => Api.get(`/certificados/${id}`),
    create: async (params) => await Api.post(`/certificados`, (params)),
    atualizar: async (id, params) => await Api.put(`/certificados/${id}/atualizar`, (params)),
    adicionar_aluno: async (id, params) => await Api.put(`/certificados/${id}/aluno`, (params)),
    concluir_aluno: async (id, otherId, params) => await Api.put(`/certificados/${id}/aluno/${otherId}/concluir`, (params)),
    excluir_treinamento: async (id) => await Api.put(`/certificados/${id}/desabilitar`),
    restaurar_treinamento: async (id) => await Api.put(`/certificados/${id}/habilitar`),
    delete: async (id) => await Api.delete(`/certificados/${id}`)
}

export default CertificadosService;