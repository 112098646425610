import React, { Fragment, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faXmark, faMinus, faTrashCan, faPen, faSpinner, faPlus, faFilePdf, faPrint } from '@fortawesome/free-solid-svg-icons'
import Moment from 'react-moment';
import 'moment-timezone';
import "../../styles/treinamento.scss";
import "../../styles/reset.scss";
import Modal from '../layouts/modal';
import ModalDangerSmall from '../layouts/modalDangerSmall';
import TreinamentosService from '../../services/treinamentos';
import UsersService from '../../services/users';
import CursosService from '../../services/cursos';
import EmpresasService from '../../services/empresas';
import LocaisService from '../../services/locais';
import Loading from '../layouts/loading';
import ImagensService from '../../services/imagens';
import gerarCertificado from '../certificados/gerador';
import gerarPDFCertificado from '../certificados/geradorPDF';
import GerarListaDePresenca from '../lista-de-presenca/gerador';
import Notification from '../layouts/notification';
import moment from 'moment-timezone';
import AlunosService from '../../services/alunos';
import { PatternFormat } from 'react-number-format';

export default function ContentSinistro() {
    const [treinamento, setTreinamento] = useState([]);
    const [permission, setPermission] = useState();
    const [aluno, setAluno] = useState();
    const [cursos, setCursos] = useState();
    const [instrutores, setInstrutores] = useState();
    const [locais, setLocais] = useState();
    const [empresas, setEmpresas] = useState();
    const [empresaTreinamento, setEmpresaTreinamento] = useState();
    const [modalEditarTreinamento, setModalEditarTreinamento] = useState(false);
    const [modalAdicionarAluno, setModalAdicionarAluno] = useState(false);
    const [modalDuplicarTreinamento, setModalDuplicarTreinamento] = useState(false);
    const [modalCriarTreinamentoDuplicado, setModalCriarTreinamentoDuplicado] = useState(false);
    const [modalEditarAluno, setModalEditarAluno] = useState(false);
    const [modalExcluirAluno, setModalExcluirAluno] = useState(false);
    const [modalExcluirTreinamento, setModalExcluirTreinamento] = useState(false);
    const [modalDataLista, setModalDataLista] = useState(false);
    const [modalAlterarStatus, setModalAlterarStatus] = useState(false);
    const [alunoAtivo, setAlunoAtivo] = useState([]);
    const [abaAtiva, setAbaAtiva] = useState('conteúdo-programático');
    const [diasTratados, setDiasTratados] = useState();
    const [datasTreinamento, setDatasTreinamento] = useState([""]);
    const [aguardandoRetorno, setAguardandoRetorno] = useState(false);
    const [camposDuplicar, setCamposDuplicar] = useState();
    const [notifications, setNotifications] = useState([]);
    const navigate = useNavigate();
    const { id } = useParams();

    function adicionarData() {
        setDatasTreinamento([ ...datasTreinamento, {dia: '' , duracao: ''} ]);
    };

    function createNotification(tipo, titulo, mensagem, tempo) {
        // TIPOS: Sucesso / Erro / Alerta / Info
        let notificacao = []
        notificacao.tipo = tipo;
        notificacao.titulo = titulo;
        notificacao.mensagem = mensagem;
        notificacao.id = notifications.length === 0 ? 1 : notifications.at(-1).id + 1 
        
        setNotifications((prevNotifications) => [...prevNotifications, notificacao]);
        
        setTimeout(() => {
            removeNotification(notificacao.id)
        }, tempo * 1000 || 5000);
    };

    function removeNotification(notificationRemove) {
        setNotifications((prevNotifications) =>
            prevNotifications.filter((notification) => notification.id !== notificationRemove)
        );
    };

    async function getTreinamento() {
        try {
            const res = await TreinamentosService.treinamento(id);
            setTreinamento(res.data);
            // console.log(res.data.aluno[0].aluno.treinamentos.find(t => t.treinamento === id).concluido);

            // console.log(res.data.aluno.find(a => a.nome === "JÉSSICA CARVALHO LIMA SCHEER  "))
            tratarDias(res.data);
        } catch (error) {
            console.log(error)
        }
    };

    async function getAluno(param) {
        const res = await AlunosService.search_cpf(param);
        if (res.data[0] !== undefined) {
            setAluno(res.data[0])
        } else {
            setAluno("");
        }
    }
    
    async function getUserLogado() {
        try {
            const res = await UsersService.logado();
            setPermission(res.data.user.permission);
        } catch (error) {
            console.log(error);
        }
    };

    async function getCursos() {
        const response = await CursosService.index();
        setCursos(response.data);
    }

    async function getLocais() {
        const response = await LocaisService.index();
        setLocais(response.data);
    }

    async function getInstrutores() {
        const response = await UsersService.index();
        setInstrutores(response.data.user.filter(({ instrutor }) => instrutor === true));
    }

    async function getEmpresas() {
        const response = await EmpresasService.index();
        setEmpresas(response.data);
    };

    async function gerarPDFCertificados(aluno) {
        const images = await ImagensService.index();
        const imageFundo = images.data.filter(({ nome }) => nome === "Fundo")[0].url;
        const assinaturaPresidente = images.data.filter(({ nome }) => nome === "Assinatura Presidente")[0].url;

        gerarPDFCertificado(treinamento, assinaturaPresidente, imageFundo, diasTratados, aluno);
    };

    async function gerarCertificados(aluno) {
        const images = await ImagensService.index();
        const logo = images.data.filter(({ nome }) => nome === "Logo")[0].url;
        const assinaturaPresidente = images.data.filter(({ nome }) => nome === "Assinatura Presidente")[0].url;

        gerarCertificado(treinamento, assinaturaPresidente, logo, diasTratados, aluno);
    };

    function gerarTodosCertificados() {
        treinamento.alunos.forEach((item) => {
            gerarCertificados(item.aluno);
        })
    };

    async function chamarLista(data) {
        const images = await ImagensService.index();
        const logo = images.data.filter(({ nome }) => nome === "Logo")[0].url;

        GerarListaDePresenca(treinamento, logo, data)
    };

    async function editTreinamento(evt) {
        evt.preventDefault();

        const formData = new FormData(evt.target)
        let form = Object.fromEntries(formData);

        form.datas = [];

        if (form.curso !== treinamento.dados_curso.curso) {
            const response_curso = await CursosService.curso(form.curso);

            form.dados_curso ={
                curso: form.curso,
                conteudo_programatico: response_curso.data.conteudo_programatico,
                titulo: response_curso.data.titulo,
                descritivo: response_curso.data.descritivo,
                carga_horaria: response_curso.data.carga_horaria,
                tipo_formacao: response_curso.data.tipo_formacao,
                info_complementar: response_curso.data.info_complementar,
                validade_certificado: response_curso.data.validade_certificado
            };
        };

        if (form.instrutor !== treinamento.dados_instrutor.instrutor._id) {
            const response_user = await UsersService.usuario(form.instrutor);
    
            form.dados_instrutor ={
                instrutor: form.instrutor,
                nome: response_user.data.name,
                funcao: response_user.data.funcoes
            };
        };

        if (treinamento.empresa && form.empresa === treinamento.empresa._id) {
            delete form.empresa;
        };

        if (form.local === treinamento.local._id) {
            delete form.local;
        };

        if (treinamento.descricao && form.descricao === treinamento.descricao) {
            delete form.descricao;
        };

        if (form.titulo === treinamento.titulo) {
            delete form.titulo;
        };
        
        delete form.curso;
        delete form.instrutor;
        delete form.vincular_empresa;

        for (let i = 0; i < datasTreinamento.length; i++) {
            let dataInput = document.querySelector(`input[name^="data${i}"]`).value;
            const duracaoInput = document.querySelector(`input[name="duracao${i}"]`);

            const dat = {
                dia: moment(dataInput).add(3, 'h'),
                duracao: duracaoInput.value
            }

            if (dataInput !== "" && duracaoInput.value !== "") {
                form.datas.push(dat);
            }
        };

        for (let propriedade in form) {
            if (/^data\d+$/.test(propriedade)) {
                delete form[propriedade];
            }

            if (propriedade.startsWith("duracao")) {
              delete form[propriedade];
            }
        }

        try {
            await TreinamentosService.editar_treinamento(id, form);
            window.location.reload();
        } catch (error) {
            console.log(error);
        }
    };

    async function atualizarTreinamento() {
        const cursoAtual = await cursos.filter(({ _id }) => _id === treinamento.dados_curso.curso)[0]
        const instrutorAtual = await instrutores.filter(({ _id }) => _id === treinamento.dados_instrutor.instrutor._id)[0]

        const form = {};

        form.dados_curso ={
            curso: cursoAtual._id,
            conteudo_programatico: cursoAtual.conteudo_programatico,
            titulo: cursoAtual.titulo,
            descritivo: cursoAtual.descritivo,
            carga_horaria: cursoAtual.carga_horaria,
            tipo_formacao: cursoAtual.tipo_formacao,
            info_complementar: cursoAtual.info_complementar,
            validade_certificado: cursoAtual.validade_certificado
        };

        form.dados_instrutor ={
            instrutor: instrutorAtual._id,
            nome: instrutorAtual.name,
            funcao: instrutorAtual.funcoes
        };

        try {
            await TreinamentosService.atualizar_treinamento(id, form);
            window.location.reload();
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getTreinamento();
        getUserLogado();
        getCursos();
        getEmpresas();
        getInstrutores();
        getLocais();
        // eslint-disable-next-line
    }, []);

    async function duplicarTreinamento(campos, opt) {
        let form = {};

        form.titulo = campos.titulo;
        campos.local === 'on' ? form.local = treinamento.local._id : form.local = opt.local;
        
        if (campos.empresa === 'on') {
            if (treinamento.empresa) {
                form.empresa = treinamento.empresa._id;
            }
        } else if (opt.vincular_empresa === "true") { 
            form.empresa = opt.empresa;
        }

        if (campos.descricao === 'on') {
            form.descricao = treinamento.descricao;
        } else if (opt.descricao !== "") {
            form.descricao = opt.descricao;
        }
        
        if (campos.curso === 'on') { 
            form.dados_curso = treinamento.dados_curso;
        } else {
            const response_curso = await CursosService.curso(opt.curso);
            
            form.dados_curso = {
                curso: opt.curso,
                conteudo_programatico: response_curso.data.conteudo_programatico,
                titulo: response_curso.data.titulo,
                descritivo: response_curso.data.descritivo,
                carga_horaria: response_curso.data.carga_horaria,
                tipo_formacao: response_curso.data.tipo_formacao,
                info_complementar: response_curso.data.info_complementar,
                validade_certificado: response_curso.data.validade_certificado
            }
        }

        if (campos.datas === 'on') {
            form.datas = [];

            treinamento.datas.forEach(element => {
                const data = {
                    dia: element.dia,
                    duracao: element.duracao
                };
                form.datas.push(data)
            });
        } else {
            form.datas = [];

            for (let i = 0; i < datasTreinamento.length; i++) {
                let dataInput = document.querySelector(`input[name^="data${i}"]`).value;
                const duracaoInput = document.querySelector(`input[name="duracao${i}"]`);
    
                const dat = {
                    dia: moment(dataInput).add(3, 'h'),
                    duracao: duracaoInput.value
                }
    
                form.datas.push(dat);
            };
        };
        
        if (campos.alunos === 'on') {
            form.alunos = [];

            treinamento.alunos.forEach(element => {
                const aluno = {aluno: element.aluno._id};
                form.alunos.push(aluno)
            });
        } else {
            form.alunos = [];
        }

        if (campos.instrutor === 'on') { 
            form.dados_instrutor = {
                funcao: treinamento.dados_instrutor.funcao,
                nome: treinamento.dados_instrutor.nome,
                instrutor: treinamento.dados_instrutor.instrutor._id
            };
        } else {
            const response_instrutor = await UsersService.usuario(opt.instrutor);

            form.dados_instrutor ={
                instrutor: opt.instrutor,
                nome: response_instrutor.data.name,
                funcao: response_instrutor.data.funcoes
            };
        }


        try {
            await TreinamentosService.duplicar(form);
            navigate('/treinamentos');
        } catch (error) {
            console.log(error);
        }
    };

    async function createAluno(event) {
        event.preventDefault();

        const formData = new FormData(event.target);
        let form = Object.fromEntries(formData);

        form.nome = form.nome.toUpperCase();

        // form.presenca.dia = {};
        
        // form.presenca = [treinamento.datas.forEach(element => {
        //     form.presenca.push(dia = element.dia)
        // })];

        // form.presenca.dia = treinamento.datas.map((item) => {
        //     item
        // });

        try {
            if (aluno === "") {
                const res = await AlunosService.create(form);
                await TreinamentosService.adicionar_aluno(id, {alunoId: res.data.aluno._id});
                await getTreinamento();
                setModalAdicionarAluno(false);
                setAluno("");
            } else {
                await TreinamentosService.adicionar_aluno(id, {alunoId: aluno._id});
                await getTreinamento();
                setModalAdicionarAluno(false);
                setAluno("");
            }
        } catch (error) {
            console.log(error)
        }
    };

    async function alterarStatus(e) {
        e.preventDefault();

        let params = {};
        const formData = new FormData(e.target);
        params.status = Object.fromEntries(formData);
        params.status.dataAlteracao = new Date();

        setAguardandoRetorno(true);
        await TreinamentosService.alterar_status(id, params);
        window.location.reload();
    };

    async function editAluno(event) {
        event.preventDefault();

        const formData = new FormData(event.target);
        let form = Object.fromEntries(formData);

        form.nome = form.nome.toUpperCase();

        try {
            await AlunosService.editar(alunoAtivo._id, form)
            getTreinamento();
            setModalEditarAluno(false);
        } catch (error) {
            console.log(error)
        }
    };

    async function excluirAluno() {
        try {
            await TreinamentosService.excluir_aluno(id, alunoAtivo._id);
            getTreinamento();
            setModalExcluirAluno(false);
        } catch (error) {
            console.log(error)
        }
    };

    async function desativarTreinamento(id) {
        await TreinamentosService.excluir_treinamento(id);
        window.location.href = '/treinamentos'
    };

    async function concluirAluno(aluno) {
    //     let data = {}
    //     data.aluno = aluno.nome;
    //     data.cpf = aluno.cpf;
    //     data.treinamento = treinamento._id;
    //     data.curso = {
    //         titulo: treinamento.curso.titulo,
    //         descritivo: treinamento.curso.descritivo,
    //         carga_horaria: treinamento.curso.carga_horaria,
    //         tipo_formacao: treinamento.curso.tipo_formacao
    //     };
    //     data.conteudo_programatico = treinamento.curso.conteudo_programatico;
    //     data.instrutor = { 
    //         nome: treinamento.instrutor.name,
    //         funcao: treinamento.instrutor.funcoes
    //     }
        
        await TreinamentosService.concluir_aluno(id, aluno._id);
        await getTreinamento();
    };

    // async function atualizarCertificado(aluno) {
    //     let data = {}
    //     data.aluno = aluno.nome;
    //     data.cpf = aluno.cpf;
    //     data.treinamento = treinamento._id;
    //     data.curso = {
    //         titulo: treinamento.curso.titulo,
    //         descritivo: treinamento.curso.descritivo,
    //         carga_horaria: treinamento.curso.carga_horaria,
    //         tipo_formacao: treinamento.curso.tipo_formacao,
    //         validade_certificado: treinamento.curso.validade_certificado
    //     };
    //     data.conteudo_programatico = treinamento.curso.conteudo_programatico;
    //     data.instrutor = { 
    //         nome: treinamento.instrutor.name,
    //         funcao: treinamento.instrutor.funcoes
    //     }
        
    //     await CertificadosService.atualizar(aluno.certificado, data);
    //     await getTreinamento();
    // };

    async function tratarDias(request) {
        const dias = [];
        const meses = [];

        try {

            await request.datas.forEach(element => {
                dias.push(element.dia);
            });
    
            await dias.sort((a, b) => new Date(a) + new Date(b)).forEach(element => {
                const dia = new Date(element);
                let mes = moment(dia).format("MMMM");
                mes = mes.charAt(0).toUpperCase() + mes.slice(1);
    
                const existe = meses.find((m) => m.mes === mes);
    
                if (!existe) {
                    meses.push({mes: mes, dia: [dia.getDate()]});
                } else {
                    existe.dia.push(dia.getDate());
                }
            });
    
            let resultado = [];
    
            await meses.forEach((objeto) => {
                let diasMes = objeto.dia.join(', ');
                resultado.push(diasMes + " de " + objeto.mes);
            });
            
            await resultado.forEach((element, index) => {
                let nova_string = element.replace(/,([^,]*)$/, ' e$1');
                resultado[index] = nova_string;
            });
    
            setDiasTratados(resultado.join(' e '));
        } catch (error) {
            console.log(error);
        }
    };

    function pegarDiaLista() {
        if (treinamento.datas.length === 1) {
            chamarLista(treinamento.datas[0]);
        } else {
            setModalDataLista(true);
        };
    };

    async function diaLista(e) {
        e.preventDefault();

        const formData = new FormData(e.target);
        let form = Object.fromEntries(formData);

        const day = form.data;

        await chamarLista(treinamento.datas[day]);
        setModalDataLista(false);
    };

// ORDENAR
    function ordemAlfabetica(a, b){
        return a.titulo < b.titulo ? -1 : a.titulo > b.titulo ? 1 : 0;
    }

    function ordemAlfabeticaAluno(a, b){
        return a.aluno.nome < b.aluno.nome ? -1 : a.aluno.nome > b.aluno.nome ? 1 : 0;
    }

    document.title = `${treinamento.titulo} - Centro de Treinamento`;

    return(
        <Fragment>
            { treinamento.length !== 0 ? 
            <main className='main'>
                <div className="conteudo-treinamento">
                    <div className="cabecalho-conteudo">
                        <h2>{treinamento.titulo}</h2>
                        <div className="buttons-conteudo">
                            <button className="button-red" onClick={() => navigate(`/treinamentos`)}>Voltar</button>
                            {treinamento.status.at(-1).situacao === "Finalizado" ? null : <button className="button-blue" onClick={() => setModalAlterarStatus(true)}>Alterar status</button>}
                            {treinamento.status.at(-1).situacao === "Finalizado" ? null : <button className="button-blue" onClick={() => atualizarTreinamento()}>Atualizar</button>}
                            <button className="button-blue" onClick={() => { setModalDuplicarTreinamento(true);}}>Duplicar</button>
                            <button className="button-cyan" onClick={() => { setModalEditarTreinamento(true); setDatasTreinamento(treinamento.datas); setEmpresaTreinamento(treinamento.empresa === undefined ? 'false' : 'true') }}>Editar</button>
                            {permission === 1 ? <button className="button-darkred" onClick={() => {setModalExcluirTreinamento(true)}}>Excluir</button> : null }
                        </div>
                    </div>
                    <div className='informacoes-treinamento'>
                        <div className="box-content">
                            <div className="campo-info campo-25">
                                <h5>Instrutor:</h5>
                                <p>{treinamento.dados_instrutor.nome}</p>
                            </div>
                            <div className="campo-info campo-25">
                                <h5>Curso:</h5>
                                <p>{treinamento.dados_curso.titulo} / {treinamento.dados_curso.tipo_formacao} { treinamento.dados_curso.info_complementar !== "" ? `(${treinamento.dados_curso.info_complementar})` : null}</p>
                            </div>
                            <div className="campo-info campo-50">
                                <h5>Descritivo:</h5>
                                <p>{treinamento.dados_curso.descritivo}</p>
                            </div>
                            <div className="campo-info campo-25">
                                <h5>Local:</h5>
                                <p>{treinamento.local.nome}</p>
                            </div>
                            <div className="campo-info campo-25">
                                <h5>Carga Horária:</h5>
                                <p>{treinamento.dados_curso.carga_horaria}h</p>
                            </div>
                            <div className="campo-info campo-25">
                                <h5>Data:</h5>
                                <p>{diasTratados} de <Moment format='YYYY'>{treinamento.datas[0]}</Moment></p>
                            </div>
                            <div className="campo-info campo-25">
                                <h5>Empresa:</h5>
                                <p>{treinamento.empresa ? treinamento.empresa.razao_social : "Não vinculado"}</p>
                            </div>
                            {treinamento.descricao !== "" ?
                            <div className="campo-info campo-100">
                                <h5>Descrição:</h5>
                                <p>{treinamento.descricao}</p>
                            </div>
                            :
                            null
                            }
                        </div>
                        <div className="abas-treinamento">
                            <div className='navegacao-abas'>
                                <div id="aba-cp" onClick={() => setAbaAtiva("conteúdo-programático")} className={abaAtiva === "conteúdo-programático" ? 'aba-active' : null}>
                                    <h2>Conteúdo</h2>
                                </div>
                                <div id="aba-alunos" onClick={() => setAbaAtiva("alunos")} className={abaAtiva === "alunos" ? 'aba-active' : null}>
                                    <h2>Alunos</h2>
                                </div>
                            </div>
                            <div className='conteudo-aba'>
                                {(() => {
                                    switch (true) {
                                        case (abaAtiva === "conteúdo-programático"):
                                            return(
                                                <ul className="conteudo-cp">
                                                    {  treinamento.dados_curso.conteudo_programatico.length === 0 ?
                                                        <p className='no-info'>Não existe conteúdo para este curso.</p>
                                                    :
                                                        treinamento.dados_curso.conteudo_programatico.map((item, index) => (
                                                            <li key={index}>{item}</li>
                                                        ))
                                                    }
                                                </ul>
                                            )
                                        case (abaAtiva === "alunos"):
                                            return(
                                                <div className="conteudo-alunos">
                                                    <div className="opcoes-aba">
                                                        {/* {treinamento.status.at(-1).situacao !== "Finalizado" ?  */}
                                                        <button className="button-green" onClick={() => {setModalAdicionarAluno(true)}}>Adicionar Aluno</button> 
                                                        {/* : permission !== 1 ? null : <button className="button-green" onClick={() => {setModalAdicionarAluno(true)}}>Adicionar Aluno</button>} */}
                                                        {/* {treinamento.status.at(-1).situacao === "Finalizado" ? null : <button className="button-cyan" onClick={() => {atualizarAlunos()}}>Atualizar Alunos</button>} */}
                                                        {/* {treinamento.status.at(-1).situacao === "Finalizado" && permission !== 1 ? null :  */}
                                                        <button className="button-blue" onClick={()=> pegarDiaLista()}>Gerar Lista de Presença</button>
                                                        {/* } */}
                                                        {/* {treinamento.status.at(-1).situacao === "Finalizado" && permission !== 1 ? null :  */}
                                                        <button className="button-blue" onClick={() => {gerarTodosCertificados()}}>Gerar Certificados</button>
                                                        {/* } */}
                                                    </div>
                                                    <div className="tabela-dados">
                                                        <div className="cabecalho-dados">
                                                            <div className="column-40">Nome</div>
                                                            <div className="column-25">CPF</div>
                                                            <div className="column-25">Status</div>
                                                        </div>
                                                        <div className="corpo-dados">
                                                            { treinamento.alunos.length === 0 ? 
                                                                    <div className="column-zerado">
                                                                        Não foi cadastrado nenhum aluno ainda.
                                                                    </div>
                                                                : (
                                                                    (treinamento.alunos.sort(ordemAlfabeticaAluno).map((item, index) => (
                                                                        <div key={index} className="row-dados">
                                                                            <div className="column-40">
                                                                                {item.aluno.nome}
                                                                            </div>
                                                                            <div className="column-25">
                                                                                {item.aluno.cpf}
                                                                            </div>
                                                                            <div className="column-25">
                                                                                {item.aluno.treinamentos.find(t => t.treinamento === id).concluido === true ? "Concluído" : "Não Concluído"}
                                                                            </div>
                                                                            <div className="column-icon">
                                                                                {item.aluno.treinamentos.find(t => t.treinamento === id).concluido === true && item.aluno.treinamentos.find(t => t.treinamento === id).certificado ? <FontAwesomeIcon icon={faFilePdf} onClick={() => {gerarPDFCertificados(item.aluno)}}/> : null}
                                                                            </div>
                                                                            <div className="column-icon">
                                                                                {item.aluno.treinamentos.find(t => t.treinamento === id).concluido === true && item.aluno.treinamentos.find(t => t.treinamento === id).certificado ? <FontAwesomeIcon icon={faPrint} onClick={()=> {gerarCertificados(item.aluno)}}/> : <FontAwesomeIcon icon={faCheck} onClick={() => {concluirAluno(item.aluno);}}/>}
                                                                            </div>
                                                                            <div className="column-icon">
                                                                                <FontAwesomeIcon icon={faPen} onClick={() => {setModalEditarAluno(true); setAlunoAtivo(item.aluno)}}/>
                                                                            </div>
                                                                            <div className="column-icon">
                                                                                <FontAwesomeIcon icon={faTrashCan} onClick={() => {setModalExcluirAluno(true); setAlunoAtivo(item.aluno)}}/>
                                                                            </div>
                                                                        </div>
                                                                    )))
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        default:
                                        break;
                                    }
                                })()}
                            </div>
                        </div>
                    </div>
                    <div className="timeline-treinamento">
                        {treinamento.status?.map((item, index) => (
                            <div key={index} className="box-status">
                                {(() => {
                                    if (index === treinamento.status.length - 1) {
                                        if (item.situacao === "Finalizado") {
                                            return(
                                                <div className="rounded-div rounded-green">
                                                    <div className="circulo circulo-green">
                                                        <FontAwesomeIcon icon={faCheck}/>
                                                    </div>
                                                </div>
                                            )
                                        } else if (item.situacao === "Pagamento Recusado"){
                                            return(
                                                <div className="rounded-div rounded-red">
                                                    <div className="circulo circulo-red">
                                                        <FontAwesomeIcon icon={faXmark}/>
                                                    </div>
                                                </div>
                                            )
                                        } else{
                                            return(
                                                <div className="rounded-div">
                                                    <div className="circulo">
                                                        <FontAwesomeIcon icon={faMinus}/>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    } else {
                                        return(
                                            <div className="rounded-div">
                                                <div className="circulo">
                                                    <FontAwesomeIcon icon={faCheck}/>
                                                </div>
                                            </div>
                                        )
                                    }
                                })()}
                                <div className='textos-status'>
                                    <h4>
                                        <Moment format="DD/MM/YY">
                                            {item.dataAlteracao}
                                        </Moment>
                                    </h4>
                                    <h5>{item.situacao}</h5>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </main> 
            :
            <Loading>
                
            </Loading>
            }
            {modalDataLista ? (
                <Modal titulo="Selecione a data" width="35%" onClose={() => {setModalDataLista(false)}}>
                    <form onSubmit={diaLista}>
                        <div id="campos-data-lista">
                            <div className="campo-form campo-100">
                                <label htmlFor="data">Situação</label>
                                <select name="data" id="data">
                                    <option value='default' disabled>Selecione</option>
                                    {treinamento.datas.map((item, index) => (
                                        <option value={index} key={index}>{moment(item.dia).format("DD/MM/YYYY")}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className='buttons-form'>
                            <button type="reset" className="button-red" onClick={() => {setModalDataLista(false)}}>Cancelar</button>
                            <button type="submit" className="button-green">Gerar</button>
                        </div>
                    </form>
                </Modal>
            ): null}
            {modalEditarTreinamento ? (
                <Modal titulo="Editar Treinamento" width="50%" onClose={() => {setModalEditarTreinamento(false); setEmpresaTreinamento(false)}}>
                    <form onSubmit={editTreinamento}>
                        <div id="campos-editar-treinamento">
                            <div className="campo-form campo-60">
                                <label htmlFor="titulo">Título</label>
                                <input type="titulo" name="titulo" id="titulo" defaultValue={treinamento.titulo}/>
                            </div>
                            <div className="campo-form campo-40">
                                <label htmlFor="curso">Curso</label>
                                <select name="curso" id="curso" defaultValue={treinamento.dados_curso.curso}>
                                    <option value="default">Selecione</option>
                                    { cursos.sort(ordemAlfabetica).map((item, index) => (
                                        <option value={item._id} key={index}>{item.titulo} / {item.tipo_formacao} { item.info_complementar !== "" ? `(${item.info_complementar})` : null}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="campo-form campo-40">
                                <label htmlFor="local">Local</label>
                                <select name="local" id="local" defaultValue={treinamento.local._id}>
                                    <option value="default">Selecione</option>
                                    { locais.map((item, index) => (
                                        <option value={item._id} key={index}>{item.nome}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="campo-form campo-30">
                                <label htmlFor="instrutor">Instrutor</label>
                                <select name="instrutor" id="instrutor" defaultValue={treinamento.dados_instrutor.instrutor._id}>
                                    <option value="default">Selecione</option>
                                    { instrutores.map((item, index) => (
                                        <option value={item._id} key={index}>{item.name}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="campo-form campo-30">
                                <label htmlFor="vincular_empresa">Vincular empresa</label>
                                <select name="vincular_empresa" id="vincular_empresa" defaultValue={treinamento.empresa === undefined ? false : true} onChange={(e) => {setEmpresaTreinamento(e.target.value)}}>
                                    <option value="default" disabled>Selecione</option>
                                    <option value={true}>Sim</option>
                                    <option value={false}>Não</option>
                                </select>
                            </div>
                            { empresaTreinamento === "true" ? <div className="campo-form campo-100">
                                <label htmlFor="empresa">Selecione a empresa</label>
                                <select name="empresa" id="empresa" defaultValue={treinamento.empresa ? treinamento.empresa._id : "default"}>
                                    { !treinamento.empresa ? <option value="default" disabled>Selecione</option> : null}
                                    { empresas.map((item, index) => (
                                        <option key={index} value={item._id}>{item.razao_social}</option>
                                    ))}
                                </select>
                            </div> 
                            : null }
                            <div className="campo-form campo-100">
                                <label htmlFor="descricao">Descrição:</label>
                                <textarea type="text" name="descricao" id="descricao" rows="3" defaultValue={treinamento.descricao}/>
                            </div>
                            <h5>Dias de Treinamento <button className='button-green' type='button' onClick={() => {adicionarData()}}><FontAwesomeIcon icon={faPlus}/></button> </h5>
                            {datasTreinamento.map((item, index) => (
                                <div className={`container-inputs`} key={index}>
                                    <div className="campo-form campo-50">
                                        <input type="date" name={`data${index}`} id={`data${index}`} value={ item.dia ? moment(item.dia).format('YYYY-MM-DD') : ""} onChange={(e) => { const newConteudos = [...datasTreinamento]; newConteudos[index].dia = e.target.value; setDatasTreinamento(newConteudos); }}/>
                                    </div>
                                    <div className="campo-form campo-40">
                                        <input type="number" name={`duracao${index}`} id={`duracao${index}`} placeholder='Duração em horas' value={item.duracao}  onChange={(e) => { const newConteudos = [...datasTreinamento]; newConteudos[index].duracao = e.target.value; setDatasTreinamento(newConteudos);}}/>
                                    </div>
                                    {datasTreinamento.length === 1 ? <button type='button' className='button-disabled button-red'><FontAwesomeIcon icon={faXmark}/></button> : <button type='button' className='button-red' onClick={() => { const newConteudos = [...datasTreinamento]; newConteudos.splice(index, 1); setDatasTreinamento(newConteudos); }}><FontAwesomeIcon icon={faXmark}/></button>}
                                </div>
                            ))}
                        </div>
                        <div className='buttons-form'>
                            <button type="reset" className="button-red" onClick={() => {setModalEditarTreinamento(false); setEmpresaTreinamento(false)}}>Cancelar</button>
                            <button type="submit" className="button-green">Salvar</button>
                        </div>
                    </form>
                </Modal>
            ): null}
            {modalAlterarStatus ? (
                <Modal titulo="Alterar Status" width="35%" onClose={() => {setModalAlterarStatus(false)}}>
                    <form onSubmit={alterarStatus}>
                        <div id="campos-nova-visita">
                            <div className="campo-form campo-100">
                                <label htmlFor="situacao">Situação</label>
                                <select name="situacao" id="situacao">
                                    <option value='default'>Selecione</option>
                                    {/* <option value='Não Concluído'>Não Concluído</option>
                                    <option value='Reagendar'>Reagendar</option> */}
                                    <option value='Cancelado'>Cancelado</option>
                                    <option value='Finalizado'>Finalizado</option>
                                </select>
                            </div>
                        </div>
                        <div className='buttons-form'>
                            <button type="reset" className="button-red" onClick={() => {setModalAlterarStatus(false)}}>Cancelar</button>
                            {aguardandoRetorno ? <button type="button" className="button-green">Aguarde<FontAwesomeIcon icon={faSpinner} spinPulse style={{marginLeft: ".5em"}}/></button> : <button type="submit" className="button-green">Salvar</button>}
                        </div>
                    </form>
                </Modal>
            ): null}
            {modalAdicionarAluno ? (
                <Modal titulo="Novo Aluno" onClose={() => {setModalAdicionarAluno(false); setAluno("")}}>
                    <form onSubmit={createAluno}>
                        <div id="campos-nova-agenda">
                            <div className="campo-form campo-45">
                                <label htmlFor="cpf">CPF</label>
                                <PatternFormat name='cpf' id='cpf' onBlur={(event) =>  (event.target.value.length !== 0 ? getAluno(event.target.value) : setAluno(""))} format="###.###.###-##" required/>
                            </div>
                            <div className="campo-form campo-55">
                                <label htmlFor="nome">Nome Completo</label>
                                <input type="text" name="nome" id="nome" defaultValue={aluno !== undefined && aluno !== "" ? aluno.nome : ""} required/>
                            </div>
                        </div>
                        <div className='buttons-form'>
                            <button type="reset" className="button-red" onClick={() => {setModalAdicionarAluno(false); setAluno("")}}>Cancelar</button>
                            <button type="submit" className="button-green">Cadastrar</button>
                        </div>
                    </form>
                </Modal>
            ): null}
            {modalDuplicarTreinamento ? (
                <Modal titulo="Duplicar Treinamento" width="25%" onClose={() => {setModalDuplicarTreinamento(false)}}>
                    <form onSubmit={(e) => {e.preventDefault(); const formData = new FormData(e.target); const form = Object.fromEntries(formData); if (Object.keys(form).length === 1) { createNotification("Erro", "Atenção", "É necessário marcar pelo menos uma opção de informação a ser duplicada")} else if (Object.keys(form).length === 8){ duplicarTreinamento(form); } else if (Object.keys(form).length === 7 && !form.alunos) { duplicarTreinamento(form) } else { setCamposDuplicar(form); setModalCriarTreinamentoDuplicado(true); }}}>
                        <div id="campos-duplicar-treinamento">
                            <div className="campo-form campo-100">
                                <label htmlFor="titulo">Titulo do novo treinamento</label>
                                <input type='text' name='titulo' id='titulo' required/>
                            </div>
                            <h5>Deseja duplicar quais informações?</h5>
                            <div className="campo-form campo-100">
                                <input type='checkbox' name='alunos' id='alunos'/>
                                <label htmlFor="alunos">Alunos</label>
                            </div>
                            <div className="campo-form campo-100">
                                <input type='checkbox' name='curso' id='curso'/>
                                <label htmlFor="curso">Curso</label>
                            </div>
                            <div className="campo-form campo-100">
                                <input type='checkbox' name='datas' id='datas'/>
                                <label htmlFor="datas">Datas</label>
                            </div>
                            <div className="campo-form campo-100">
                                <input type='checkbox' name='descricao' id='descricao'/>
                                <label htmlFor="descricao">Descrição</label>
                            </div>
                            <div className="campo-form campo-100">
                                <input type='checkbox' name='empresa' id='empresa'/>
                                <label htmlFor="empresa">Empresa vinculada</label>
                            </div>
                            <div className="campo-form campo-100">
                                <input type='checkbox' name='instrutor' id='instrutor'/>
                                <label htmlFor="instrutor">Instrutor</label>
                            </div>
                            <div className="campo-form campo-100">
                                <input type='checkbox' name='local' id='local'/>
                                <label htmlFor="local">Local de treinamento</label>
                            </div>
                        </div>
                        <div className='buttons-form'>
                            <button type="reset" className="button-red" onClick={() => {setModalDuplicarTreinamento(false)}}>Cancelar</button>
                            <button type="submit" className="button-green">Duplicar</button>
                        </div>
                    </form>
                </Modal>
            ): null}
            {modalCriarTreinamentoDuplicado ? (
                <Modal titulo="Duplicar Treinamento" width="50%" onClose={() => {setModalCriarTreinamentoDuplicado(false); setEmpresaTreinamento(false)}}>
                    <form onSubmit={(e) => {e.preventDefault(); const formData = new FormData(e.target); const form = Object.fromEntries(formData); duplicarTreinamento(camposDuplicar, form)}}>
                        <div id="campos-editar-treinamento">
                            {camposDuplicar.curso ?
                                null 
                            : 
                            <div className={Object.keys(camposDuplicar).length === 7 ? 'campo-form campo-100' : 'campo-form campo-50'}>
                                <label htmlFor="curso">Curso</label>
                                <select name="curso" id="curso" defaultValue="" required>
                                    <option value="" disabled>Selecione</option>
                                    { cursos.sort(ordemAlfabetica).map((item, index) => (
                                        <option value={item._id} key={index}>{item.titulo} / {item.tipo_formacao} { item.info_complementar !== "" ? `(${item.info_complementar})` : null}</option>
                                    ))}
                                </select>
                            </div>
                            }
                            {camposDuplicar.local ?
                                null 
                            : 
                            <div className="campo-form campo-50">
                                <label htmlFor="local">Local</label>
                                <select name="local" id="local" defaultValue="" required>
                                    <option value="" disabled>Selecione</option>
                                    { locais.map((item, index) => (
                                        <option value={item._id} key={index}>{item.nome}</option>
                                    ))}
                                </select>
                            </div>
                            }
                            {camposDuplicar.instrutor ?
                                null 
                            : 
                            <div className="campo-form campo-50">
                                <label htmlFor="instrutor">Instrutor</label>
                                <select name="instrutor" id="instrutor" defaultValue="" required>
                                    <option value="" disabled>Selecione</option>
                                    { instrutores.map((item, index) => (
                                        <option value={item._id} key={index}>{item.name}</option>
                                    ))}
                                </select>
                            </div>
                            }
                            {camposDuplicar.empresa ?
                                null 
                            : 
                                <div className="campo-form campo-50">
                                    <label htmlFor="vincular_empresa">Vincular empresa</label>
                                    <select name="vincular_empresa" id="vincular_empresa" defaultValue="" required onChange={(e) => {setEmpresaTreinamento(e.target.value)}}>
                                        <option value="" disabled>Selecione</option>
                                        <option value={true}>Sim</option>
                                        <option value={false}>Não</option>
                                    </select>
                                </div>
                            }
                            { empresaTreinamento === "true" ? <div className="campo-form campo-100">
                                <label htmlFor="empresa">Selecione a empresa</label>
                                <select name="empresa" id="empresa" defaultValue="" required>
                                    <option value="" disabled>Selecione</option>
                                    { empresas.map((item, index) => (
                                        <option key={index} value={item._id}>{item.razao_social}</option>
                                    ))}
                                </select>
                            </div> 
                            : null }
                            {camposDuplicar.descricao ?
                                null 
                            : 
                                <div className="campo-form campo-100">
                                    <label htmlFor="descricao">Descrição:</label>
                                    <textarea type="text" name="descricao" id="descricao" rows="3"/>
                                </div>
                            }
                            {camposDuplicar.datas ?
                                null 
                            : 
                                <Fragment>
                                    <h5>Dias de Treinamento <button className='button-green' type='button' onClick={() => {adicionarData()}}><FontAwesomeIcon icon={faPlus}/></button> </h5>
                                    {datasTreinamento.map((item, index) => (
                                        <div className={`container-inputs`} key={index}>
                                            <div className="campo-form campo-50">
                                                <input type="date" name={`data${index}`} id={`data${index}`} onChange={(e) => { const newConteudos = [...datasTreinamento]; newConteudos[index].dia = e.target.value; setDatasTreinamento(newConteudos); }}/>
                                            </div>
                                            <div className="campo-form campo-40">
                                                <input type="number" name={`duracao${index}`} id={`duracao${index}`} placeholder='Duração em horas' onChange={(e) => { const newConteudos = [...datasTreinamento]; newConteudos[index].duracao = e.target.value; setDatasTreinamento(newConteudos);}}/>
                                            </div>
                                            {datasTreinamento.length === 1 ? <button type='button' className='button-disabled button-red'><FontAwesomeIcon icon={faXmark}/></button> : <button type='button' className='button-red' onClick={() => { const newConteudos = [...datasTreinamento]; newConteudos.splice(index, 1); setDatasTreinamento(newConteudos); }}><FontAwesomeIcon icon={faXmark}/></button>}
                                        </div>
                                    ))}
                                </Fragment>
                            }
                        </div>
                        <div className='buttons-form'>
                            <button type="reset" className="button-red" onClick={() => {setModalCriarTreinamentoDuplicado(false); setEmpresaTreinamento(false)}}>Cancelar</button>
                            <button type="submit" className="button-green">Salvar</button>
                        </div>
                    </form>
                </Modal>
            ): null}
            {modalExcluirTreinamento ? (
                <ModalDangerSmall mensagem="Deseja realmente excluir esse treinamento?" onClose={() => {setModalExcluirTreinamento(false)}}>
                    <div className='buttons-form'>
                        <button type="reset" className="button-red" onClick={() => {setModalExcluirTreinamento(false)}}>Cancelar</button>
                        <button type="submit" className="button-blue" onClick={() => {desativarTreinamento(treinamento._id)}}>Excluir</button>
                    </div>
                </ModalDangerSmall>
            ): null}
            {/* {modalCertificadoAluno ? (
                <ModalCertificado certificadoId={certificadoAtivo} onClose={() => {setModalCertificadoAluno(false)}}>
                    
                </ModalCertificado>
            ): null} */}
            {modalEditarAluno ? (
                <Modal titulo="Editar Aluno" onClose={() => {setModalEditarAluno(false)}}>
                    <form onSubmit={editAluno}>
                        <div id="campos-nova-agenda">
                            <div className="campo-form campo-55">
                                <label htmlFor="nome">Nome Completo</label>
                                <input type="text" name="nome" id="nome" defaultValue={alunoAtivo.nome}/>
                            </div>
                            <div className="campo-form campo-45">
                                <label htmlFor="cpf">CPF</label>
                                <input type="text" name="cpf" id="cpf" defaultValue={alunoAtivo.cpf}/>
                            </div>
                        </div>
                        <div className='buttons-form'>
                            <button type="reset" className="button-red" onClick={() => {setModalEditarAluno(false)}}>Cancelar</button>
                            <button type="submit" className="button-green">Salvar</button>
                        </div>
                    </form>
                </Modal>
            ): null}
            {modalExcluirAluno ? (
                <ModalDangerSmall mensagem={['Deseja excluir o aluno', <br/>,<span>{alunoAtivo.nome}</span>,"?"]} onClose={() => {setModalExcluirAluno(false)}}>
                    <div className='buttons-form'>
                        <button type="reset" className="button-red" onClick={() => {setModalExcluirAluno(false)}}>Cancelar</button>
                        <button type="submit" className="button-blue" onClick={() => {excluirAluno()}}>Excluir</button>
                    </div>
                </ModalDangerSmall>
            ): null}
            { notifications.length !== 0 ?
                <div className='area-notification'>
                    {notifications.map((item, index) =>(
                        <Notification tipo={item.tipo} titulo={item.titulo} mensagem={item.mensagem} onClose={() => removeNotification(item.id)} key={index}/>
                    ))}
                </div>
            : null }
        </Fragment>
    )
};